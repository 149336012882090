// Components/Modals/ImpressionTagModal.js
import React, { useEffect, useState, useCallback } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { saveBookingCreatives } from "../../API/managetags";

const ImpressionTagModal = ({ show, handleClose, token, customer_id, refreshData, impressionTagData, campaigns, bookings, creatives, impressiontags }) => {
  // Initial form state
  const createInitialState = useCallback(
    () => ({
      campaign_id: null,
      booking_id: null,
      creatives: [],
    }),
    []
  );

  const [state, setState] = useState(createInitialState);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedCreatives, setSelectedCreatives] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [bookingOptions, setBookingOptions] = useState([]);
  const [creativeOptions, setCreativeOptions] = useState([]);
  const [originalCreatives, setOriginalCreatives] = useState([]);

  const resetForm = () => {
    setState(createInitialState());
    setSelectedCampaign(null);
    setSelectedBooking(null);
    setSelectedCreatives([]);
  };

  useEffect(() => {
    if (show) {
      resetForm();
    }
  }, [show]);

  // Dynamically update booking options when campaign changes
  const handleCampaignChange = (selectedOption) => {
    setSelectedCampaign(selectedOption);

    // Filter bookings by selected campaign
    const associatedBookings = bookings
      .filter((booking) => booking.campaign_id === selectedOption.value)
      .map((booking) => ({
        value: booking.id,
        label: booking.booking_name,
      }));

    setBookingOptions(associatedBookings);
    setSelectedBooking(null); // Reset booking selection when campaign changes
  };

  // Handle booking selection
  const handleBookingChange = (selectedOption) => {
    setSelectedBooking(selectedOption);

    // Filter impression tags for the selected booking
    const associatedCreatives = impressiontags
      .filter((tag) => tag.booking_id === selectedOption.value) // Find tags matching this booking
      .map((tag) => {
        const creative = creatives.find((creative) => creative.id === tag.creative_id); // Match with creative details
        return {
          value: creative.id,
          label: creative.description,
        };

        // Only include creatives that match the booking's type
        // if (creative && creative.creative_type === selectedBookingType) {
        //   return {
        //     value: creative.id,
        //     label: creative.description,
        //   };
        // }
        // return null;
      })
      .filter((creative) => creative !== null); // Remove nulls

    // Find the selected booking to get its booking_type
    const selectedBookingType = bookings.find((booking) => booking.id === selectedOption.value)?.booking_type;

    // Set creative options that match selected booking type
    const availableCreatives = creatives
      .filter((creative) => creative.creative_type === selectedBookingType)
      .map((creative) => ({
        value: creative.id,
        label: creative.description,
      }));

    // Set the selected creatives
    setSelectedCreatives(associatedCreatives);
    setOriginalCreatives(associatedCreatives);

    // Set the available creatives
    setCreativeOptions(availableCreatives);
  };

  console.log(bookings);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    // Extract creative IDs
    const payload = selectedCreatives.map((creative) => creative.value);

    try {
      console.log("Payload", payload);
      await saveBookingCreatives(selectedBooking.value, payload, token);
      refreshData();
      handleClose();
    } catch (error) {
      console.error("Error saving campaign creatives: ", error);
    }
  };

  // Validate form
  useEffect(() => {
    const validateForm = () => {
      const isValid = selectedCampaign && selectedBooking && selectedCreatives.length > 0;

      const creativesChanged = selectedCreatives.length !== originalCreatives.length || selectedCreatives.some((selected) => !originalCreatives.some((original) => original.value === selected.value));

      setIsFormValid(isValid && creativesChanged);
    };
    validateForm();
  }, [selectedCampaign, selectedBooking, selectedCreatives, originalCreatives]);

  // Campaign and Creative options
  const campaignOptions = campaigns.map((campaign) => ({
    value: campaign.id,
    label: campaign.campaign_name,
  }));

  // const creativeOptions = creatives.map((creative) => ({
  //   value: creative.id,
  //   label: creative.description,
  // }));

  return (
    <Modal size="lg" show={show} onHide={handleClose} onExited={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-4 w-100 fw-bolder bw-text-primary">Select Campaign, Booking, and Creatives</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row className="my-3">
          <Col>
            <Form onSubmit={handleSave}>
              {/* Campaign Dropdown */}
              <Form.Group controlId="campaign">
                <div className="tiny-heading">Campaign</div>
                <Select
                  value={selectedCampaign}
                  onChange={handleCampaignChange}
                  options={campaignOptions}
                  placeholder="Select Campaign"
                  classNamePrefix="react-select"
                  styles={selectStyles}
                  isDisabled={selectedCampaign !== null} // Disable after selection
                />
              </Form.Group>

              {/* Booking Dropdown (appears after campaign selection) */}
              {/* {selectedCampaign && ( */}
              <Form.Group controlId="booking" className="mt-3">
                <div className="tiny-heading">Booking</div>
                <Select value={selectedBooking} onChange={handleBookingChange} options={bookingOptions} placeholder="Select Booking" classNamePrefix="react-select" styles={selectStyles} isDisabled={!selectedCampaign} />
              </Form.Group>
              {/* )} */}

              {/* Creative Multi-Select (appears after booking selection) */}
              {/* {selectedBooking && ( */}
              <Form.Group controlId="creatives" className="mt-3">
                <div className="tiny-heading">Creatives</div>
                <Select
                  isMulti
                  value={selectedCreatives}
                  onChange={setSelectedCreatives}
                  options={creativeOptions}
                  placeholder="Select Creatives"
                  classNamePrefix="react-select"
                  styles={selectStyles}
                  isDisabled={!selectedCampaign && !selectedBooking}
                />
              </Form.Group>
              {/* )} */}

              {/* Save Button */}
              <Row className="mt-5">
                <Col className="d-flex justify-content-end mt-5">
                  <Button type="submit" className="bw-button-primary bw-button" disabled={!isFormValid}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

// Styling for react-select components
const selectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    background: "transparent",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6c757d",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#007bff" : state.isFocused ? "#d9d9d9" : "white",
    "&:hover": {
      backgroundColor: "#d9d9d9",
      color: "black",
    },
  }),
};

export default ImpressionTagModal;