import React from "react";
import { MdOutlineAddCircle } from "react-icons/md";

function AddButton({ text, onClick }) {
  return (
    <button className="bw-button bw-bg-secondary text-white d-flex align-items-center" type="button" onClick={onClick}>
      <div className="me-2 mb-1">
        <MdOutlineAddCircle size={20} />
      </div>
      <div className="fs-6 fw-medium">{text}</div>
    </button>
  );
}

export default AddButton;
