import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";
import { updateUser } from "../../API/admin";
import { useAuth } from "../../Auth/AuthContext";

const EditUserModal = ({
                         show,
                         closeModal,
                         handleUpdateUser,
                         userData,
                         validateFormInputs,
                         checkExistingUsers,
                         users,
                         handleShowError
                       }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});
  const [inputErrors, setInputErrors] = useState({
    email: false,
    name: false
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const { authState } = useAuth();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setInputErrors({
      email: false,
      name: false
    });
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInputErrors({ email: false, name: false });

    const updatedUserData = {
      email: updatedUser.email,
      name: updatedUser.name
    };

    const errors = validateFormInputs(updatedUserData);

    setInputErrors(errors);

    // If there are any errors, return early to prevent form submission
    if (errors.email || errors.name) {
      setEmailErrorMessage("Invalid email address.");
      return;
    }

    // Check if user already exists
    if (updatedUser.email !== currentUser.email) {
      if (checkExistingUsers(users, updatedUser)) {
        setInputErrors((prev) => ({
          ...prev,
          email: true
        }));
        setEmailErrorMessage("A user with the email already exists.");
        return;
      }
    }

    try {
      await updateUser(updatedUser, authState.token);

      setInputErrors({
        email: false,
        name: false
      });

      handleUpdateUser();
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("An unexpected error occurred:", error.message);
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
      setUpdatedUser(userData);
    }
  }, [userData]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold bw-text-primary">Edit user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <Form.Group className="mt-3">
            <Form.Label className={`${inputErrors.name && "text-danger"}`}>Name</Form.Label>
            <Form.Control type="text" name="name" value={updatedUser.name ? updatedUser.name : ""} onChange={handleInputChange} className={`${inputErrors.name && "border-danger"}`} />
            {inputErrors.name && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                Invalid name.
              </div>
            )}
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className={`${inputErrors.email && "text-danger"}`}>Email address</Form.Label>
            <Form.Control type="text" name="email" value={updatedUser.email ? updatedUser.email : ""} onChange={handleInputChange} className={`${inputErrors.email && "border-danger"}`} />
            {inputErrors.email && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                {emailErrorMessage}
              </div>
            )}
          </Form.Group>
          <div className="mt-5 mb-2 d-flex justify-content-end">
            <Button className="me-2 bw-button bw-button-secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" className="w-25 me-2 bw-button bw-button-primary">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserModal;
