// Pages/ManageTags.js
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import AddButton from "../Components/Buttons/AddButton";
import SubHeader from "../Components/Text/SubHeader";
import { useTagData } from "../Hooks/useTagData";
import SelectACustomerImage from "../Images/select-a-client.svg";
import CreativesTab from "../Components/TabContent/CreativesTab";
import BookingsTab from "../Components/TabContent/BookingsTab";
import SiteTagsTab from "../Components/TabContent/SiteTagsTab";
import CampaignsTab from "../Components/TabContent/CampaignsTab";
import ImpressionTagsTab from "../Components/TabContent/ImpressionTagsTab";
import FilesTab from "../Components/TabContent/FilesTab";
import Select from "react-select";
import CreativeModal from "../Components/Modals/CreativeModal";
import CustomerModal from "../Components/Modals/CustomerModal";
import SiteTagModal from "../Components/Modals/SiteTagModal";
import CampaignModal from "../Components/Modals/CampaignModal";
import BookingModal from "../Components/Modals/BookingModal";
import ImpressionTagModal from "../Components/Modals/ImpressionTagModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../Auth/AuthContext";

const ManageTags = ({ currentCustomerId, setCurrentCustomerId }) => {
  const {
    customerData,
    customerContent,
    selectedCustomerId,
    setSelectedCustomerId,
    updateCustomerContent,
    refreshCustomers,
    channels,
    modals,
    handleShowModal,
    handleCloseModal
  } = useTagData(currentCustomerId, setCurrentCustomerId);
  const [activeTab, setActiveTab] = useState("campaigns");
  const { authState } = useAuth();
  const { role } = authState; // Extracting role directly
  const token = authState.token;
  console.debug("ManageTags.js: role", role); // Debug log
  useEffect(() => {
    if (customerData.length === 1) {
      const customerId = customerData[0].id;
      setSelectedCustomerId(customerId);
      setCurrentCustomerId(customerId);
      updateCustomerContent(customerId);
    } else if (!currentCustomerId && customerData.length > 0) {
      setSelectedCustomerId("");
    }
  }, [currentCustomerId, customerData]);

  const handleCustomerSelection = async (selectedOption) => {
    const customerId = selectedOption ? selectedOption.value : null;
    setSelectedCustomerId(customerId);
    setCurrentCustomerId(customerId);
    updateCustomerContent(customerId);
  };

  const showModal = (type, data) => {
    console.log(`Showing modal of type: ${type} with data:`, data); // Debug log
    const modalMap = {
      campaign: "campaignModal",
      creative: "creativeModal",
      siteTag: "siteTagModal",
      booking: "bookingModal",
      impressionTag: "impressionTagModal",
      customer: "customerModal" // Ensure customer modal is included
    };
    handleShowModal(modalMap[type], data);
    console.log(`Modal state after showModal:`, modals); // Debug log
  };

  const customerOptions = customerData.map((customer) => ({
    value: customer.id,
    label: customer.cust_name
  }));

  return (
    <>
      <Col className="flex-grow-0 bw-bg-secondary px-3">
        <Row className="my-3 mx-3">
          <Col className="col-12 col-md-6 d-flex">
            <Form className="w-50 me-3">
              <Form.Group>
                <Select
                  value={customerOptions.find((option) => option.value === selectedCustomerId) || null}
                  onChange={handleCustomerSelection}
                  options={customerOptions}
                  placeholder="Select Client"
                  isClearable
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Form>
            {/* {role !== "ROLE_CUSTOMER" && role !== "ROLE_CUSTOMER_ADMIN" && <AddButton text="Add Client" onClick={() => showModal("customer", null)} />} */}
          </Col>
        </Row>
      </Col>

      <Col className="flex-grow-1 overflow-y-scroll bg-white mx-3 my-3 px-3">
        {selectedCustomerId ? (
          <Tabs id="uncontrolled-tab" className="my-3" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="campaigns" title="Campaigns">
              <SubHeader text="Campaigns" />
              <AddButton text="Add Campaign" onClick={() => showModal("campaign", null)} />
              <CampaignsTab data={customerContent.campaignsData}
                            showEditCampaign={(campaign) => showModal("campaign", campaign)} />
            </Tab>
            <Tab eventKey="bookings" title="Bookings">
              <SubHeader text="Bookings" />
              <AddButton text="Add Booking" onClick={() => showModal("booking", null)} />
              <BookingsTab data={customerContent.bookingsData}
                           showEditBooking={(booking) => showModal("booking", booking)} />
            </Tab>
            <Tab eventKey="creatives" title="Creatives">
              <SubHeader text="Creatives" />
              <AddButton text="Add Creative" onClick={() => showModal("creative", null)} />
              <CreativesTab data={customerContent.creativesData}
                            showEditCreative={(creative) => showModal("creative", creative)} />
            </Tab>
            <Tab eventKey="impressiontags" title="Impression Tags">
              <SubHeader text="Impression Tags" />
              <AddButton text="Add Impression Tag" onClick={() => showModal("impressionTag", null)} />
              <ImpressionTagsTab data={customerContent.impressionTagsData} creativeData={customerContent.creativesData}
                                 showEditImpressionTag={(impressionTag) => showModal("impressionTag", impressionTag)} />
            </Tab>
            <Tab eventKey="sitetags" title="Site Tags">
              <SubHeader text="Site Tags" />
              <AddButton text="Add Site Tag" onClick={() => showModal("siteTag", null)} />
              <SiteTagsTab data={customerContent.siteTagsData}
                           showEditSiteTag={(siteTag) => showModal("siteTag", siteTag)} />
            </Tab>
            {/* <Tab eventKey="clienttab" title="Client">
              <SubHeader text="Client" />
              <ClientTab customerData={customerData} selectedCustomer={selectedCustomerId} refreshCustomers={refreshCustomers} />
            </Tab> */}
            {/*<Tab eventKey="files" title="Files">*/}
            {/*  <FilesTab />*/}
            {/*</Tab>*/}
          </Tabs>
        ) : (
          <div className="placeholder-content">
            <img src={SelectACustomerImage} alt="select a client" />
          </div>
        )}
      </Col>

      <CustomerModal show={modals.customerModal?.show} handleClose={() => handleCloseModal("customerModal")}
                     refreshCustomers={refreshCustomers} token={token} />

      <CreativeModal
        show={modals.creativeModal?.show}
        handleClose={() => handleCloseModal("creativeModal")}
        customer_id={selectedCustomerId}
        refreshData={() => updateCustomerContent(selectedCustomerId)}
        token={token}
        creativeData={modals.creativeModal?.data}
      />

      <SiteTagModal
        show={modals.siteTagModal?.show}
        handleClose={() => handleCloseModal("siteTagModal")}
        customer_id={selectedCustomerId}
        refreshData={() => updateCustomerContent(selectedCustomerId)}
        token={token}
        siteTagData={modals.siteTagModal?.data}
      />

      <CampaignModal
        show={modals.campaignModal?.show}
        handleClose={() => handleCloseModal("campaignModal")}
        customer_id={selectedCustomerId}
        refreshData={() => updateCustomerContent(selectedCustomerId)}
        token={token}
        campaignData={modals.campaignModal?.data}
        channels={channels} // Pass channels to the modal
      />
      <BookingModal
        show={modals.bookingModal?.show}
        handleClose={() => handleCloseModal("bookingModal")}
        customer_id={selectedCustomerId}
        refreshData={() => updateCustomerContent(selectedCustomerId)}
        token={token}
        bookingData={modals.bookingModal?.data} // Keep booking-specific data here
        channels={channels} // Pass channels to the modal
        campaigns={customerContent.campaignsData} // Pass campaigns here
      />


      <ImpressionTagModal
        show={modals.impressionTagModal?.show}
        handleClose={() => handleCloseModal("impressionTagModal")}
        customer_id={selectedCustomerId}
        campaigns={customerContent.campaignsData}
        bookings={customerContent.bookingsData}
        creatives={customerContent.creativesData}
        impressiontags={customerContent.impressionTagsData}
        refreshData={() => updateCustomerContent(selectedCustomerId)}
        token={token}
        impressionTagData={modals.impressionTagModal?.data}
      />

      <ToastContainer position="bottom-center" />
    </>
  );
};

export default ManageTags;
