import React from "react";
import { Spinner } from "react-bootstrap";

const LoginButton = ({ loading }) => {
  return (
    <button className="bw-bg-secondary p-2 border-0 w-100 text-end fw-bold" type="submit" disabled={loading}>
      {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "LOG ON"}
    </button>
  );
};

export default LoginButton;
