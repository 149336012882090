import React from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { numberWithCommas } from "../../Utilities/Utils";

const AllResponsesByRegionModal = ({ showModal, handleClose, regionData }) => {
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton closeVariant="white" className="bw-bg-primary text-light">
        All Responses by Region
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="col-12">
            <Table>
              <thead>
              <tr>
                <th className="">Region</th>
                <th className="text-center">Responses</th>
                <th className="text-center">Visit %</th>
              </tr>
              </thead>
              <tbody>
              {regionData.map((region) => (
                <tr key={region.Region}>
                  <td>{region.Region}</td>
                  <td className="text-center">{numberWithCommas(region.Responses)}</td>
                  <td className="text-center">{numberWithCommas(parseFloat(region?.Percentage) * 100, 1)}%</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AllResponsesByRegionModal;
