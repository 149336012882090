// Components/Nav/TopNav.js
import React, { useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useAuth } from "../../Auth/AuthContext";
import transparentLogo from "../../Images/white_text_logo.svg";
import ExportAllDataToExcel from "../../Utilities/ExportAllDataToExcel";

function TopNav({ setView }) {
  const { logout } = useAuth();
  const [activeItem, setActiveItem] = useState("dashboard");

  const handleNavClick = (view) => {
    setActiveItem(view);
    setView(view);
  };

  const handleHelpClick = () => {
    const subject = encodeURIComponent("Help Request");
    const body = encodeURIComponent("Hi Helpdesk, I need assistance with...");
    window.location.href = `mailto:help@infinitum8.com?subject=${subject}&body=${body}`;
  };

  return (
    <Navbar className="bw-bg-primary p-0" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/home">
          <img src={transparentLogo} alt="Infinitum" width="280px" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className={activeItem === "dashboard" ? "active" : ""} onClick={() => handleNavClick("dashboard")}>
              Dashboard
            </Nav.Link>
            <Nav.Link className={activeItem === "manage" ? "active" : ""} onClick={() => handleNavClick("manage")}>
              Manage Tags
            </Nav.Link>
            <Nav.Link className={activeItem === "admin" ? "active" : ""} onClick={() => handleNavClick("admin")}>
              Admin
            </Nav.Link>
          </Nav>
          <div className="d-flex ms-auto py-4 py-lg-0">
            {activeItem === "dashboard" && (
              <div className="me-3">
                <ExportAllDataToExcel />
              </div>
            )}
            <Button variant="outline-light" size="sm" className="me-2" onClick={handleHelpClick}>
              Help
            </Button>
            <Button variant="outline-light" size="sm" onClick={() => logout()}>
              Logout
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNav;
