import React from "react";

function PrimaryButton({ text, onClick, disabled, type = "button" }) {
  return (
    <button className="bw-button-primary bw-button d-flex align-items-center" type={type} onClick={onClick} disabled={disabled}>
      <span className="fs-7 text-center w-100">{text}</span>
    </button>
  );
}

export default PrimaryButton;
