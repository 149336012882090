import React from "react";
import { Button, Modal } from "react-bootstrap";

const TermsAndConditionsModal = ({ open, handleClose }) => {
  return (
    <Modal show={open} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="terms-modal-title">
          Platform License Agreement for Infinitum8 Reporting Platform
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="terms-modal-body">
        <p>
          <strong>LICENSOR:</strong> Mime Analytics NZ Limited (NZBN: 9429030069152) (“Supplier”)
        </p>
        <h2>BACKGROUND</h2>
        <p>This document records the terms and conditions of a licence which is granted to an authorised user
          (“Licensee”) of the Mime Infinitum8 Platform including its operating software.</p>
        <p>
          Acceptance of the terms of this Licence is implied each time a Licensee accesses and uses the Platform and
          upon acceptance a legally binding agreement is formed between the Supplier and the Licensee but strictly on
          the basis of
          the terms and conditions set out below.
        </p>
        <p>The Platform is a Broadcast Video on Demand tool which is used to measure the success of media campaigns.</p>
        <p>This is a user-only licence which entitles a Licensee to use the Platform and the Software to monitor the use
          and effectiveness of media placed by them by way of reports and information on their profile page.</p>
        <p>Use of the Platform and its Software is strictly limited to applications enabling these functions in
          accordance with this agreement and other directions by the Supplier from time to time.</p>

        <h2>1. DEFINITIONS</h2>
        <p>1. Defined terms: Unless the context requires otherwise, the following capitalised terms have the meaning
          ascribed to them:</p>
        <ul>
          <li>1.1 Authorised User means a person or entity authorised by the Supplier to operate the Platform to access
            their profile and reports.
          </li>
          <li>1.2 Licence has the meaning given to that term in clause 2.1 below.</li>
          <li>1.3 Licensee means an Authorised User of the Platform, when authorised by the Supplier to operate it.
            Authorisation is strictly subject to the terms and conditions of this Licence Agreement.
          </li>
          <li>1.4 Platform Upgrades means upgrades to the Platform and its operation that do not entail Software
            Upgrades.
          </li>
          <li>1.5 Services means services carried out by Supplier in connection with the Software, its deployment,
            Customisation and use.
          </li>
          <li>
            1.6 Software means the computer programs owned by the Supplier and used by the Licensee in connection with
            operation of the Platform consisting of a set of instructions or statements in object code or
            machine-readable medium
            together with any associated materials and documentation and any Software Upgrades.
          </li>
          <li>1.7 Software Upgrades means enhancements and new versions of the Software released by the Supplier after
            the commencement of this Agreement.
          </li>
          <li>1.8 Working Days means any day other than a Saturday or Sunday or public holiday in New Zealand.</li>
        </ul>

        <h2>2. Licence</h2>
        <p>2.1 The Supplier grants to each Authorised User as Licensee a non-transferable and non-exclusive licence to
          operate the Platform for its designed purposes of measuring media performance according to its methods of
          operation.</p>
        <p>2.2 The Licensee may not use the Platform in any way other than set out in this Agreement.</p>

        <h2>3. Duration of Licence</h2>
        <p>3.1 The Licence will commence on the first date that the Platform is accessed by the Licensee and will
          continue for the duration of authorised use.</p>

        <h2>4. Ownership</h2>
        <p>
          4.1 Copyright and all other intellectual property rights in the Platform and the Software and all associated
          documentation are and shall at all times remain the sole property of the Supplier and the Licensee’s only
          rights to use
          it are as set out in this Agreement.
        </p>
        <p>4.2 Any software, know how, techniques or ideas which may be developed by the Supplier (at the request of the
          Licensee or otherwise) during the term of this Agreement, will belong to the Supplier.</p>
        <p>4.3 Data and other information belonging to the Licensee shall remain the property of the Licensee. The
          Supplier will exercise reasonable care to ensure this data is not corrupted lost or used inappropriately.</p>

        <h2>5. Copying and Modifications</h2>
        <p>5.1 The Licensee will not permit the Platform or its Software (in whole or in part) to be copied.</p>
        <p>5.2 The Licensee may not modify the Platform or its Software or cause or permit the disassembly or
          de-compilation of the Software or any part thereof.</p>

        <h2>6. Warranty</h2>
        <p>6.1 The Supplier warrants that:</p>
        <ul>
          <li>(a) it is the sole owner of the Platform and its Software and that they do not infringe the industrial or
            intellectual property rights of any person;
          </li>
          <li>(b) The Platform and its Software shall comply with any other requirements set out in this Agreement</li>
        </ul>
        <p>6.2 The Software shall be provided in accordance with all applicable laws.</p>
        <p>6.3 It will use best endeavours to ensure that the Software will be free of viruses or other code that may
          disrupt or damage Licensee data or Licensee’s IT environment.</p>

        <h2>7. Minimum Operating Requirements</h2>
        <p>7.1 The Licensee undertakes to ensure that at all times the following minimum requirements are available to
          the Licensee and its employees and contractors for access to the Platform:</p>
        <ul>
          <li>7.1.1 Internet browser upgraded to the following minimum version:</li>
          <ul>
            <li>7.1.1.1 Google Chrome: Version 61+</li>
            <li>7.1.1.2 Mozilla Firefox: Version 60+</li>
            <li>7.1.1.3 Microsoft Edge: Version 79+ (based on Chromium)</li>
            <li>7.1.1.4 Safari: Version 11+</li>
            <li>7.1.1.5 Opera: Version 48+</li>
          </ul>
        </ul>

        <h2>8. Licensee Data</h2>
        <p>
          8.1 The Supplier will not be responsible for retaining any of the Licensee’s data or reports after termination
          of this Agreement. The Licensee’s data may be deleted promptly after this Agreement is terminated and from
          backups
          during scheduled backup rotation.
        </p>
        <p>8.2 Unless otherwise agreed, the Supplier will not keep, restore, or export out any Licensee data after
          termination of this Agreement.</p>
        <p>
          8.3 User Data does not belong to the Supplier, however the Supplier has an irrevocable, royalty-free perpetual
          license to use and process it for product operation and development, and may also use it on an anonymised
          basis for
          promotion and commercialisation.
        </p>
        <p>8.4 The Supplier will not use any data that is the property of the Licensee except in accordance with the
          terms of this Agreement.</p>

        <h2>9. Upgrades</h2>
        <p>9.1 Platform Upgrades or Software Upgrades may be made available to the Licensee as soon as practically
          possible after the Supplier is satisfied as to the outcome of testing of each upgrade.</p>
        <p>9.2 The Supplier will endeavour to notify the Licensee of any upgrade no less than 3 working days before the
          Upgrade is implemented.</p>

        <h2>10. Confidentiality</h2>
        <p>
          10.1 The Licensee shall treat as confidential the Supplier's intellectual and industrial property rights in
          the platform and its Software. The Supplier shall treat as confidential any information relating to the
          Licensee and its
          business which comes into its possession in connection with the Licensees use of the Platform.
        </p>
        <p>
          10.2 Neither party shall disclose the other's confidential information to a third party unless requested by
          the owner of that information or required by a court order. The operation of this clause shall survive the
          termination of
          this Agreement.
        </p>

        <h2>11. Exclusion of Implied Guarantees and Warranties</h2>
        <p>
          11.1 The Licensee represents to the Supplier that the Platform, the Software and any additional services
          supplied by the Supplier are being acquired for the purposes of a business and that it has not relied on any
          representation
          made by the Supplier which has not been stated expressly in this Agreement.
        </p>
        <p>
          11.2 To the maximum extent permitted by law all guarantees, warranties and conditions implied on the part of
          the Supplier are excluded and the parties contract out of the provisions and warranties in the Contract and
          Commercial
          Law Act 2017, the Consumer Guarantees Act 1993, and liability under the Fair Trading Act 1986.
        </p>
        <p>
          11.3 The Supplier does not warrant that the Platform or the Software will meet the Licensee's expectations or
          requirements or that its operation will be uninterrupted or error free, nor that any particular business
          outcome sought
          by the Licensee from using the Platform will be achieved.
        </p>

        <h2>12. Limitation of Liability</h2>
        <p>
          12.1 Subject to clause 12.3, the Supplier will not be liable for indirect or consequential damages claims or
          for any loss of business, property, profit however caused, which may be alleged suffered or incurred or which
          may arise
          directly or indirectly in respect of the Platform or its Software, any services supplied, nor in respect of
          any failure or omission on the part of the Supplier to comply with its obligations under this Agreement.
        </p>
        <p>
          12.2 In any event the Supplier's total liability for damages under this Agreement whether in contract, tort
          (including negligence) or under any other legal claim shall be limited, at the option of the Supplier, to any
          one or more
          of the following, as may be appropriate:
        </p>
        <ul>
          <li>12.2.1 rectify or replace the Software; or</li>
          <li>12.2.2 supply of Services supplied again; or</li>
          <li>12.2.3 refund of any monies paid by the Licensee to the Supplier within the 12 months preceding
            notification of the claim.
          </li>
        </ul>
        <p>12.3 The Supplier shall not limit or exclude its liability for:</p>
        <ul>
          <li>12.3.1 fraud or wilful breach;</li>
          <li>12.3.2 Its liability for breach of confidentiality.</li>
        </ul>

        <h2>13. Termination and Access Rights</h2>
        <p>13.1 The Supplier may terminate this Agreement immediately by notice in writing if the Licensee:</p>
        <ul>
          <li>13.1.1 fails to make any payment due under this Agreement within 14 days of its due date; and/or</li>
          <li>13.1.2 breaches any clause of this Agreement and such breach is not remedied within thirty (30) days of
            written notice by the Supplier.
          </li>
          <li>13.1.3 the Broadcaster’s Master Licence Agreement is terminated.</li>
        </ul>
        <p>13.2 On termination of this Agreement, the Licence and all rights as an Authorised User of the Platform will
          immediately cease.</p>
        <p>
          13.3 Termination of this Agreement shall not affect any obligation of the Licensee to pay fees or charges
          which may have become payable under this Agreement before such termination and shall not affect any right at
          law or equity
          relating to any obligation under this Agreement and which accrued to either party before such termination.
        </p>
        <p>
          13.4 As an alternative to termination (but on every occasion entirely at the Supplier’s discretion) if at any
          time the Licensee fails to make a payment or is otherwise in breach the Supplier may suspend rights of access
          to the
          Platform to give the Licensee an opportunity to remedy the non-payment/breach and the Supplier may re-instate
          access on receipt of full payment/ when every breach has been remedied, subject however to any terms or
          conditions the
          Supplier may then impose.
        </p>

        <h2>14. Force Majeure</h2>
        <p>
          14.1 Neither party will be liable for any delay or failure to perform its obligations under this Agreement if
          such failure or delay is due to an event or events over which that party could not reasonably have exercised
          control.
        </p>

        <h2>15. General</h2>
        <p>The governing law of this Agreement is the law of New Zealand. The parties submit to the exclusive
          jurisdiction of the courts of New Zealand.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditionsModal;
