import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { updateAgency } from "../../API/admin";
import { useAuth } from "../../Auth/AuthContext";

const EditAgencyModal = ({
                           show,
                           closeModal,
                           handleUpdateAgency,
                           agencyData,
                           validateFormInputs,
                           checkExistingAgency,
                           handleShowError
                         }) => {
  const [currentAgency, setCurrentAgency] = useState({});
  const [updatedAgency, setUpdatedAgency] = useState({});
  const [inputErrors, setInputErrors] = useState({
    email: false,
    domain: false,
    name: false
  });
  const [domainErrorMessage, setDomainErrorMessage] = useState("");

  const { authState } = useAuth();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAgency((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setInputErrors({
      email: false,
      domain: false,
      name: false
    });
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInputErrors({ email: false, domain: false, name: false });

    const updatedAgencyData = {
      email: updatedAgency.admin_email,
      domain: updatedAgency.domain,
      name: updatedAgency.agency_name
    };

    const errors = validateFormInputs(updatedAgencyData);

    setInputErrors(errors);

    // If there are any errors, return early to prevent form submission
    if (errors.email || errors.domain || errors.name) {
      if (errors.domain) {
        setDomainErrorMessage("Invalid domain.");
      }
      return;
    }

    if (updatedAgency.domain !== currentAgency.domain) {
      if (checkExistingAgency(updatedAgency.domain)) {
        setInputErrors((prev) => ({
          ...prev,
          domain: true
        }));
        setDomainErrorMessage("An agency with this domain already exists.");
        return;
      }
    }

    try {
      await updateAgency(updatedAgency, authState.token);

      setInputErrors({
        email: false,
        domain: false,
        name: false
      });

      handleUpdateAgency();
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error updating agency:", error.message);
      }
    }
  };

  useEffect(() => {
    if (agencyData) {
      setCurrentAgency(agencyData);
      setUpdatedAgency(agencyData);
    }
  }, [agencyData]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold bw-text-primary">Edit agency</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className={`${inputErrors.domain && "text-danger"}`}>Domain</Form.Label>
            <Form.Control type="text" name="domain" value={updatedAgency.domain ? updatedAgency.domain : ""} onChange={handleInputChange} className={`${inputErrors.domain && "border-danger"}`} />
            {inputErrors.domain && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                {domainErrorMessage}
              </div>
            )}
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className={`${inputErrors.name && "text-danger"}`}>Name</Form.Label>
            <Form.Control type="text" name="agency_name" value={updatedAgency.agency_name ? updatedAgency.agency_name : ""} onChange={handleInputChange} className={`${inputErrors.name && "border-danger"}`} />
            {inputErrors.name && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                Invalid name.
              </div>
            )}
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label className={`${inputErrors.email && "text-danger"}`}>Email address</Form.Label>
            <Form.Control type="text" name="admin_email" value={updatedAgency.admin_email ? updatedAgency.admin_email : ""} onChange={handleInputChange} className={`${inputErrors.email && "border-danger"}`} />
            {inputErrors.email && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                Invalid email address.
              </div>
            )}
          </Form.Group>
          <div className="mt-5 mb-2 d-flex justify-content-end">
            <Button className="me-2 bw-button bw-button-secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" className="w-25 me-2 bw-button bw-button-primary">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditAgencyModal;
