import React from "react";
import { Bar } from "react-chartjs-2";
import { useData } from "../../Data/GraphContext";

const SingleHorizontalBarChart = ({
                                    percent = false,
                                    usePrimaryColor = false,
                                    useSecondaryColor = false,
                                    dataKey,
                                    labelKey
                                  }) => {
  const { creativePerformanceSummary } = useData();
  const tableData = creativePerformanceSummary?.table_data || [];
  const metaData = creativePerformanceSummary?.meta_data || [];

  const rootStyles = getComputedStyle(document.documentElement);
  const secondaryColor = rootStyles.getPropertyValue("--secondary-color").trim();
  const primaryColor = rootStyles.getPropertyValue("--primary-color").trim();
  const tintColor = rootStyles.getPropertyValue("--tint-color").trim();

  const dataValues = tableData.map((item) => (percent ? (item[dataKey] * 100).toFixed(1) : item[dataKey]));
  const labels = tableData.map((item) => item[labelKey]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: percent ? "Creative Performance %" : "Creative Performance",
        data: dataValues,
        backgroundColor: usePrimaryColor ? primaryColor : useSecondaryColor ? secondaryColor : tintColor,
        borderColor: usePrimaryColor ? primaryColor : useSecondaryColor ? secondaryColor : tintColor,
        borderWidth: 1
      }
    ]
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        max: dataKey === "Total_Responses" ? metaData.max_x_resp : metaData.max_x_perc * 100,
        ticks: {
          callback: function(value) {
            return percent ? `${value}%` : value;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // Hide the legend for this - simple graph
      },
      title: {
        display: false,
        text: percent ? "Share of Visits" : "Visits"
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += percent ? `${context.raw}%` : context.raw;
            return label;
          }
        }
      }
    }
  };

  return (
    // <div className="chart-container">
    <div className="d-flex h-100">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default SingleHorizontalBarChart;
