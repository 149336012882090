// Components/Dashboards/SummaryDashboard.js
import React from "react";
import { Col, Row } from "react-bootstrap";
import ResponseByRegion from "../Charts/ResponseByRegion";
import VisitResponseTiming from "../Charts/VisitResponseTiming";
import VisitsOverTime from "../Charts/VisitsOverTime";
import PerformanceProgress from "../Charts/PerformanceProgress";
import ImpressionsBeforeVisit from "../Charts/ImpressionsBeforeVisit";
import Correlation from "../Charts/Correlation";
// import GaugeChart from "../Charts/GaugeChart";

const SummaryDashboard = () => {
  return (
    <Row className="g-3 pt-2 pb-4">
      <Col xs={12} lg={3} className="pb-1">
        <PerformanceProgress />
      </Col>

      <Col xs={12} lg={4} className="pb-1">
        <ImpressionsBeforeVisit />
      </Col>

      <Col xs={12} lg={5} className="pb-1">
        <ResponseByRegion />
      </Col>

      <Col xs={12} lg={6} className="pb-1">
        <VisitsOverTime />
      </Col>

      <Col xs={12} lg={3} className="pb-1">
        <Correlation type={"correlation"} />
      </Col>

      <Col xs={12} lg={3} className="pb-1">
        <Correlation type={"pareto"} />
      </Col>

      <Col xs={6} className="pb-1 d-flex">
        <VisitResponseTiming percent />
      </Col>

      <Col xs={6} className="pb-1 d-flex">
        <VisitResponseTiming />
      </Col>
    </Row>
  );
};

export default SummaryDashboard;
