import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { saveNewSiteTag, updateSiteTag } from "../../API/managetags";

const SiteTagModal = ({
                        show,
                        handleClose,
                        customer_id,
                        token,
                        refreshData,
                        siteTagData
                      }) => {
  console.log("SiteTagModal rendered with show:", show); // Debug log
  console.log("SiteTagModal received siteTagData:", siteTagData); // Debug log

  // Retrieve user data from local storage
  const user = JSON.parse(localStorage.getItem("user"));
  const entity_id = user?.entity_id || null;
  const entity_type = user?.role || "";

  // Memoize the initial site tag state function
  const createInitialSiteTagState = useCallback(() => ({
    customer_id: customer_id,
    tag_description: "",
    tag: ""
  }), [customer_id]);

  // Use the function to set the initial state
  const [siteTag, setSiteTag] = useState(createInitialSiteTagState());
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (siteTagData) {
      console.log("Setting site tag state with siteTagData:", siteTagData);
      setSiteTag({
        customer_id: siteTagData.customer_id,
        tag_description: siteTagData.tag_description,
        tag: siteTagData.tag,
        id: siteTagData.id // Include the ID for update
      });
    } else {
      console.log("Setting site tag state with initial state");
      setSiteTag(createInitialSiteTagState());
    }
  }, [siteTagData, createInitialSiteTagState]);

  useEffect(() => {
    const validateForm = () => {
      const isValid = siteTag.tag_description.trim() !== "";
      setIsFormValid(isValid);
    };

    validateForm();
  }, [siteTag]);

  const handleFieldChange = (fieldName, value) => {
    setSiteTag((prevState) => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  const handleSaveSiteTag = async (e) => {
    e.preventDefault();
    console.log("Submitting site tag:", siteTag);

    try {
      if (siteTagData) {
        console.log("Updating site tag:", siteTag);
        await updateSiteTag(siteTag, token);
      } else {
        console.log("Creating new site tag:", siteTag);
        await saveNewSiteTag(siteTag, token);
      }
      refreshData();
      handleClose();
    } catch (error) {
      console.error("Error occurred while saving site tag:", error);
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-4 w-100 fw-bolder bw-text-primary">{siteTagData ? "Update Site Tag" : "New Site Tag"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveSiteTag}>
          <Row>
            <Col>
              <Form.Group controlId="tagDescription">
                <Form.Label className="tiny-heading">Tag Description</Form.Label>
                <Form.Control type="text" value={siteTag.tag_description} onChange={(e) => handleFieldChange("tag_description", e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-end mt-4">
              <Button className="me-2 bw-button-secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="w-25 me-2 bw-button bw-button-primary" disabled={!isFormValid}>
                {siteTagData ? "Update Site Tag" : "Save Site Tag"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SiteTagModal;
