import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import CreativeTypeButtonGroup from "../Buttons/CreativeTypeButtonGroup";
import { saveNewCreative, updateCreative } from "../../API/managetags";

const CreativeModal = ({
                         show,
                         handleClose,
                         customer_id,
                         token,
                         refreshData,
                         creativeData
                       }) => {
  const createInitialCreativeState = () => ({
    customer_id: customer_id,
    // key_number: "",
    description: "",
    creative_format: "",
    creative_type: 1,
  });

  const [creative, setCreative] = useState(
    creativeData || createInitialCreativeState()
  );

  const resetForm = () => {
    setCreative(createInitialCreativeState());
  };

  useEffect(() => {
    if (show && !creativeData) {
      resetForm();
    } else if (creativeData) {
      setCreative(creativeData);
    }
  }, [show, creativeData]);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFieldChange = (name, value) => {
    setCreative((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleTypeChange = (type) => {
    setCreative((prevState) => ({
      ...prevState,
      creative_type: type
    }));
  };

  const handleSaveCreative = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    if (creativeData) {
      await updateCreative(creative, token);
    } else {
      await saveNewCreative(creative, token);
    }

    refreshData();
    handleClose();
  };

  useEffect(() => {
    setCreative((prevState) => ({
      ...prevState,
      customer_id: customer_id
    }));
  }, [customer_id]);

  useEffect(() => {
    const validateForm = () => {
      const isValid =
        // creative?.key_number.trim().length > 0 &&
        creative?.description.trim().length > 0 && creative?.creative_format.trim().length > 0;

      setIsFormValid(isValid);
    };

    validateForm();
  }, [creative]);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-4 w-100 fw-bolder bw-text-primary">{creativeData ? "Update Creative" : "New Creative"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSaveCreative}>
          <Row className="mb-3">
            <Col>
              <CreativeTypeButtonGroup selectedType={creative.creative_type} onTypeChange={handleTypeChange} />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group controlId="keyNumber">
                <Form.Label className="tiny-heading">Key Number</Form.Label>
                <Form.Control type="text" value={creative.key_number} onChange={(e) => handleFieldChange("key_number", e.target.value)} required />
              </Form.Group>
            </Col>
          </Row> */}
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="description">
                <Form.Label className="tiny-heading">Description</Form.Label>
                <Form.Control as="textarea" rows={3} value={creative.description} onChange={(e) => handleFieldChange("description", e.target.value)} maxLength={200} required />
                <div className="d-flex justify-content-between">
                  <div className="info-description">Description of the creative</div>
                  <div className={`${creative.description.length < 180 ? "bw-text-primary" : "bw-text-danger"} fs-8 pt-1 text-end fw-bold`}>{200 - creative.description.length}/200</div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="creativeFormat">
                <Form.Label className="tiny-heading">Creative Length or Size</Form.Label>
                <Form.Control type="text" value={creative.creative_format} onChange={(e) => handleFieldChange("creative_format", e.target.value)} maxLength={50} required />
                <div className="d-flex justify-content-between">
                  <div className="info-description">Add ad duration (e.g. 15 secs) or static image size (e.g. 300 x 250)</div>
                  <div className={`${creative.creative_format.length < 45 ? "bw-text-primary" : "bw-text-danger"} fs-8 pt-1 text-end fw-bold`}>{50 - creative.creative_format.length}/50</div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-end mt-4">
              <Button className="me-2 bw-button-secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="w-25 me-2 bw-button bw-button-primary" disabled={!isFormValid}>
                {creativeData ? "Update Creative" : "Save Creative"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreativeModal;
