// Components/Charts/ImpressionsBeforeVisit_tt.js
import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title
} from "chart.js";
import { useData } from "../../Data/GraphContext";
import { numberWithCommas } from "../../Utilities/Utils";
import HelpTooltip from "../Shared/HelpToolTip";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const ImpressionsBeforeVisit = React.memo(() => {
  const { impressionsBeforeVisit } = useData();
  // console.log("Data fetched for ImpressionsBeforeVisit:", impressionsBeforeVisit);
  const rootStyles = getComputedStyle(document.documentElement);
  const secondaryColor = rootStyles.getPropertyValue("--secondary-color").trim();

  const BarChart = React.memo(() => {
    const { data, options } = useMemo(() => {
      const rawData = impressionsBeforeVisit?.table_data || [];
      const rawLabels = rawData.map((item) => item.Views);

      const sortedData = rawData.map((item) => item.Impressions_Before_Visit).sort((a, b) => b - a);

      const data = {
        labels: rawLabels,
        datasets: [
          {
            label: "Impressions before Visit",
            data: sortedData,
            backgroundColor: secondaryColor
          }
        ]
      };

      const options = {
        indexAxis: "y",
        scales: {
          x: {
            min: 0,
            max: impressionsBeforeVisit.meta_data.max_val,
            title: {
              display: true,
              text: "Impressions"
            }
          },
          y: {
            reverse: false,
            title: {
              display: true,
              text: "Views"
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: impressionsBeforeVisit?.chartTitle || "Impressions before Visit",
            font: {
              size: 18,
              style: "bold"
            }
          },
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false
      };

      return { data, options };
    }, [impressionsBeforeVisit, secondaryColor]);

    // console.log("BarChart data:", data);

    return <Bar data={data} options={options} />;
  });

  return (
    <Card className="h-100">
      <Card.Header className="border-0 bw-bg-primary">
        <div className="fw-bold">Impressions before Visit</div>
        <HelpTooltip helpText="How many times an Ad impression was served before a Visit, along with the average of all Visits by impressions served."></HelpTooltip>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <Row className="h-100 p-1 w-100">
          <Col className="d-flex flex-column">
            <div className="flex-grow-1 pb-3">{impressionsBeforeVisit ? <BarChart /> : <div>Loading...</div>}</div>
            <div className="d-flex flex-grow-0">
              <Card className="overflow-hidden w-100 no-border-card">
                <Card.Body className="bw-bg-primary rounded">
                  <div className="fw-bold text-center">{"Avg. Imps/Visit"}</div>
                  <div className="fs-4 fw-bold text-center">{numberWithCommas(impressionsBeforeVisit?.summary_data?.Total_Views_Occurrence, 1)}</div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});

export default ImpressionsBeforeVisit;
