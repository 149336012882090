// Components/TabContent/UsersTab.js
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import PrimaryButton from "../Buttons/PrimaryButton";
import AdminButton from "../Buttons/AdminButton";
import EditUserModal from "../Modals/EditUserModal";
import NotificationModal from "../Modals/NotificationModal";
import ConfirmRemoveModal from "../Modals/ConfirmRemoveModal";
import AdminNotification from "../Alerts/AdminNotification";
import { deleteUser, getUsers, saveNewUser } from "../../API/admin";
import { useAuth } from "../../Auth/AuthContext";
import FilteredTable from "../Shared/FilteredTable";  // Import the new FilteredTable

const UsersTab = () => {
  const [modals, setModals] = useState({
    edit: false,
    added: false,
    remove: false,
    error: false
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [alerts, setAlerts] = useState({
    updateSuccess: false,
    removeSuccess: false
  });
  const [currentUser, setCurrentUser] = useState({});
  const [users, setUsers] = useState([]);
  const [inputValues, setInputValues] = useState({
    name: "",
    email: ""
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false
  });
  const { authState } = useAuth();

  const ref = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleShowModal = (modal, user) => {
    setModals((prev) => ({
      ...prev,
      [modal]: true
    }));
    setCurrentUser(user);
  };

  const handleShowError = (message) => {
    setModals((prev) => ({
      ...prev,
      edit: false,
      error: true
    }));
    setErrorMessage(message);
  };

  const handleCloseModal = (modal) => {
    setModals((prev) => ({
      ...prev,
      [modal]: false
    }));
    setCurrentUser({});
  };

  const handleScrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleUpdateUser = () => {
    handleCloseModal("edit");

    fetchUsers();

    setTimeout(() => {
      setAlerts((prev) => ({
        ...prev,
        updateSuccess: true
      }));
    }, 300);
  };

  const handleRemoveUser = async (userId) => {
    try {
      await deleteUser(userId, authState.token);
      console.log("User deleted successfully");

      handleCloseModal("remove");
      fetchUsers();
      setTimeout(() => {
        setAlerts((prev) => ({
          ...prev,
          removeSuccess: true
        }));
      }, 300);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error deleting user:", error.message);
      }
    }
  };

  const validateFormInputs = (inputValues) => {
    let errors = {
      email: false,
      name: false
    };

    if (!inputValues.email.includes("@") || inputValues.email.trim().length === 0) {
      errors.email = true;
    }

    if (inputValues.name.trim().length === 0) {
      errors.name = true;
    }

    return errors;
  };

  const checkExistingUsers = (users, inputValues) => {
    return users.some((user) => user.email.toLowerCase() === inputValues.email.toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({ name: false, email: false });

    const errors = validateFormInputs(inputValues);

    setFormErrors(errors);

    if (errors.name || errors.email) {
      return;
    }

    if (checkExistingUsers(users, inputValues)) {
      setModals((prev) => ({
        ...prev,
        error: true
      }));
      setErrorMessage("A user with this email already exists.");
      return;
    }

    try {
      const newUser = await saveNewUser(
        {
          email: inputValues.email,
          name: inputValues.name,
          username: inputValues.email
        },
        authState.token
      );
      console.log("New user created: ", newUser);

      setCurrentUser((prev) => ({
        ...prev,
        email: inputValues.email
      }));

      setInputValues({
        name: "",
        email: ""
      });

      fetchUsers();

      setTimeout(() => {
        setModals((prev) => ({
          ...prev,
          added: true
        }));
      }, 200);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error creating user:", error.message);
      }
    }
  };

  const handleCloseAlert = (alert) => {
    setAlerts((prev) => ({
      ...prev,
      [alert]: false
    }));
  };

  const fetchUsers = useCallback(async () => {
    try {
      const data = await getUsers(authState.token);
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  }, [authState.token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (alerts.removeSuccess || alerts.updateSuccess) {
      handleScrollToBottom();
    }
  }, [alerts]);

  // Define columns for the users table
  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name"  // Accessor for user name
    },
    {
      Header: "Email",
      accessor: "email"  // Accessor for user email
    },
    {
      Header: "Options",
      accessor: "options",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-end gap-4">
          <AdminButton
            text="Edit user"
            underline={true}
            onClick={() => handleShowModal("edit", row.original)}  // Pass the user to the edit handler
          />
          <AdminButton
            text="Remove"
            showIcon={true}
            onClick={() => handleShowModal("remove", row.original)}  // Pass the user to the remove handler
          />
        </div>
      )
    }
  ], [handleShowModal]);

  return (
    <>
      <div className="fs-4 w-100 fw-bolder bw-text-primary mt-4">Add user</div>
      <div style={{ maxWidth: "28rem" }}>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className={`${formErrors.name && "text-danger"}`}>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={inputValues.name}
              onChange={handleInputChange}
              className={`${formErrors.name && "border-danger"}`}
            />
            {formErrors.name && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                Please enter a name.
              </div>
            )}
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label className={`${formErrors.email && "text-danger"}`}>Email address</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={inputValues.email}
              onChange={handleInputChange}
              className={`${formErrors.email && "border-danger"}`}
            />
            {formErrors.email && (
              <div className="text-danger fs-7" style={{ position: "absolute" }}>
                Invalid email address.
              </div>
            )}
          </Form.Group>
          <div className="mt-4">
            <PrimaryButton text="Confirm" type="submit" />
          </div>
        </Form>
      </div>

      <div className="mt-5">
        <div className="border-bottom pb-1 mb-2">
          <div className="fs-4 w-100 fw-bolder bw-text-primary mt-4">Users</div>
        </div>

        {/* Use FilteredTable to display users */}
        <FilteredTable columns={columns} data={users} />

        <div ref={ref}></div>
        <AdminNotification
          show={alerts.updateSuccess}
          handleClose={() => handleCloseAlert("updateSuccess")}
          text="User updated successfully."
        />
        <AdminNotification
          show={alerts.removeSuccess}
          handleClose={() => handleCloseAlert("removeSuccess")}
          text="User has been removed."
        />
        <EditUserModal
          show={modals.edit}
          handleUpdateUser={handleUpdateUser}
          closeModal={() => handleCloseModal("edit")}
          userData={currentUser}
          validateFormInputs={validateFormInputs}
          checkExistingUsers={checkExistingUsers}
          users={users}
          handleShowError={handleShowError}
        />
        <ConfirmRemoveModal
          show={modals.remove}
          handleClose={() => handleCloseModal("remove")}
          itemType="user"
          item={currentUser}
          handleRemove={handleRemoveUser}
        />
        <NotificationModal
          show={modals.added}
          handleClose={() => handleCloseModal("added")}
          headingText="User added"
          itemText={currentUser.email}
          bodyText="has been added as a user. They will receive an email with their login details shortly."
        />
        <NotificationModal
          show={modals.error}
          handleClose={() => handleCloseModal("error")}
          headingText="Error"
          bodyText={errorMessage}
        />
      </div>
    </>
  );
};

export default UsersTab;
