// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./Utilities/ProtectedRoute";
import { useAuth } from "./Auth/AuthContext";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import AcceptInvitationPage from "./Pages/AcceptInvitationPage";

function App() {
  const { authState } = useAuth();

  // Log the API Base URL
  useEffect(() => {
    console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
  }, []); // Empty dependency array ensures this runs once when the app mounts

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute user={authState.user}>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/accept-invitation/:token" element={<AcceptInvitationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
