// Auth/AuthProvider.js
import React, { useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import axiosInstance from "../API/base";


const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: null,  // The token for authenticated requests
    role: null,   // The current user's role
    isAuth: false // Boolean to determine if the user is authenticated
  });

  // Login function
  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post("/login", { email, password }); // Use axiosInstance here

      // Assuming the response contains both access_token and refresh_token
      const { access_token, refresh_token, role, entity_id } = response.data;

      const user = { email, role, entity_id };

      // Update the auth state
      setAuthState({ token: access_token, role, user, isAuth: true });

      // Store user data and tokens in localStorage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);  // Store refresh token

      return { status: "success", user };
    } catch (error) {
      return { status: "failure", error: error.message };
    }
  };

  // Function to refresh the access token
  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      // Use axiosInstance here to post to /refresh-token
      const response = await axiosInstance.post("/refresh-token", { token: refreshToken });

      const newAccessToken = response.data.access_token;

      // Store the new access token in localStorage
      localStorage.setItem("accessToken", newAccessToken);

      // Update auth state with the new token
      setAuthState((prevState) => ({
        ...prevState,
        token: newAccessToken
      }));

      return newAccessToken;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();  // Log out if token refresh fails
      throw error;
    }
  };

  // Function to log out the user
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");  // Remove refresh token too

    setAuthState({ token: null, role: null, user: null, isAuth: false });
  };

  // Load user data from localStorage when the app loads
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const storedToken = localStorage.getItem("accessToken");

    if (storedUser && storedToken) {
      setAuthState({
        token: storedToken,
        role: storedUser.role,
        user: storedUser,
        isAuth: true
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authState, login, logout, refreshAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
