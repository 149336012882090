import React from "react";
import { Col, Row } from "react-bootstrap";
import { FiArchive, FiEdit } from "react-icons/fi";
import CopyToClipboardButton from "./Buttons/CopyToClipboardButton";

function TableOptions({ archive, copy, edit, clipboardText, onCopy, onDelete, onEdit }) {
  return (
    <Row className="d-flex justify-content-center">
      <Col className="col-12 d-flex justify-content-start">
        {copy && <CopyToClipboardButton textToCopy={clipboardText} onClick={onCopy} title="Copy" className="pointer" size={18} />}

        {edit && <FiEdit title="Edit" className="pointer me-5" size={18} onClick={onEdit} />}
        {archive && <FiArchive className="pointer text-danger" onClick={onDelete} size={18} title="Archive" />}
      </Col>
    </Row>
  );
}

export default TableOptions;
