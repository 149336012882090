// Components/Charts/GaugeChart
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const GaugeChart = ({ value, chartType }) => {
  // console.debug("value: ", value, " chartType: ", chartType);
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const maxValue = chartType === "correlation" ? 1 : 10;
    const data = {
      datasets: [
        {
          data: [value, maxValue - value],
          backgroundColor: ["#00a2e8", "rgba(0,0,0,0.1)"],
          borderColor: ["rgba(255,26,104,0)", "rgba(0,0,0,0)"],
          borderWidth: 0,
          cutout: "80%",
          circumference: 180,
          rotation: 270
        }
      ]
    };

    const gaugeChartText = {
      id: "gaugeChartText",

      afterDatasetsDraw(chart, args, pluginOptions) {
        const {
          ctx
        } = chart;
        const maxValue = chartType === "correlation" ? 1 : 10;
        const minValue = chartType === "correlation" ? 0 : 1;
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        const score = data.datasets[0].data[0];
        const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
        ctx.fillStyle = "#fff"; // text color
        const degToRad = (deg) => (deg * Math.PI) / 180;
        ctx.save();
        ctx.font = "15px sans-serif";
        // ctx.fillStyle = "#ffd002"; // text color
        const labelOffset = 10;
        ctx.textBaseline = "top";
        ctx.textAlign = "right";
        ctx.fillText(minValue.toString(), xCoor - outerRadius + labelOffset, yCoor);
        ctx.textAlign = "left";
        ctx.fillText(maxValue.toString(), xCoor + outerRadius - labelOffset, yCoor);
        if (chartType === "correlation") {
          const labels = [
            { text: "Weak", angle: 150 },
            // { text: ".3", angle: 126 },
            { text: "Moderate", angle: 90 },
            // { text: ".7", angle: 54 },
            { text: "Strong", angle: 30 }
          ];

          labels.forEach(label => {
            // const angleRad = (label.angle * Math.PI) / 180; // Convert degrees to radians
            const angleRad = degToRad(label.angle); // Convert degrees to radians
            const x = xCoor + (outerRadius + labelOffset) * Math.cos(angleRad);
            const y = yCoor - (outerRadius + labelOffset) * Math.sin(angleRad);
            // console.debug(`label.text: ${label.text}, centerX: ${xCoor}, centerY: ${yCoor}, angleRad: ${angleRad}, x: ${x}, y: ${y}`);
            ctx.save();
            ctx.translate(x, y);
            ctx.rotate(-angleRad + Math.PI / 2); // Rotate to keep text horizontal
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(label.text, 0, 0);
            ctx.restore();
          });
        }


        ctx.textBaseline = "bottom";
        ctx.font = "40px sans-serif";
        ctx.textAlign = "center";
        ctx.fillText(`${score.toFixed(chartType === "correlation" ? 1 : 0)}`, xCoor, yCoor);
        ctx.restore();
      }
    };

    const chartInstance = new Chart(ctx, {
      type: "doughnut",
      data,
      options: {
        layout: {
          padding: {
            top: 0,
            bottom: 0,
            left: 10,
            right: 10
          }
        },
        aspectRatio: 1.5,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      },
      plugins: [gaugeChartText]
    });

    return () => {
      chartInstance.destroy();
    };
  }, [value, chartType]);

  return <canvas ref={chartRef} style={{ width: "100%", height: "200px" }}></canvas>;
};

export default GaugeChart;
