// Components/Shared/DropDownSelect.js
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

const DropDownSelect = ({ title, options, onApply, disabled, reset }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    title === "Client"
      ? options
      : [
        {
          id: 0,
          display_name: "All"
        }
      ]
  );
  const previousSelectedOptions = useRef(selectedOptions); // Track previous state to prevent unnecessary updates

  // Determine if a non-"All" selection is active
  const isFiltered = selectedOptions.some((option) => option.id !== 0);

  // Handles checkbox change
  const handleCheckboxChange = (option) => {
    if (option.display_name === "All") {
      if (selectedOptions.some((item) => item.id === 0)) {
        // 'All' is currently selected, deselect it and select everything else
        setSelectedOptions(options.filter((opt) => opt.id !== 0));
      } else {
        // 'All' is not selected, so select it and deselect everything else
        setSelectedOptions([{ id: 0, display_name: "All" }]);
      }
    } else {
      setSelectedOptions((prevSelectedOptions) => {
        const isSelected = prevSelectedOptions.some((item) => item.id === option.id);

        if (isSelected) {
          // If the option is already selected, remove it from the selected options
          const newSelectedOptions = prevSelectedOptions.filter((item) => item.id !== option.id);
          // If no options are left, revert to "All"
          return newSelectedOptions.length > 0 ? newSelectedOptions : [{ id: 0, display_name: "All" }];
        } else {
          // Add the option to the selected options and remove 'All' if it was selected
          return [...prevSelectedOptions.filter((item) => item.id !== 0), option];
        }
      });
    }
  };

  // Logic to handle "All" selection only when dropdown closes
  const handleDropdownToggle = (isOpen) => {
    if (!isOpen) {
      // Dropdown is being closed, check if all non-'All' options are selected
      const nonAllOptionsSelected = selectedOptions.length === options.length - 1 && selectedOptions.every((option) => option.id !== 0);

      if (nonAllOptionsSelected) {
        // If all options except 'All' are selected, reset to 'All'
        setSelectedOptions([{ id: 0, display_name: "All" }]);
      }
    }
  };

  // Reset logic
  useEffect(() => {
    if (reset) {
      // If reset is true, deselect all options except "All"
      setSelectedOptions([{ id: 0, display_name: "All" }]);
    }
  }, [reset]);

  // Guard useEffect to prevent unnecessary API calls
  useEffect(() => {
    const selectedIds = selectedOptions.map((option) => option.id);

    // Avoid triggering onApply if nothing has changed
    if (JSON.stringify(selectedOptions) !== JSON.stringify(previousSelectedOptions.current)) {
      previousSelectedOptions.current = selectedOptions; // Update previous state

      // Handle "All" selection separately
      if (selectedOptions.some((option) => option.id === 0)) {
        // If "All" is selected, pass a special signal to return all data
        onApply(title, []);
      } else if (selectedIds.length > 0 && title !== "Client") {
        onApply(title, selectedIds);
      } else if (selectedIds.length > 0 && title === "Client") {
        onApply(selectedIds[0]);
      }
    }
  }, [selectedOptions, title, onApply]);

  return (
    <Dropdown className="me-3" onToggle={handleDropdownToggle}>
      <Dropdown.Toggle
        id="region-dropdown"
        className="px-3"
        variant={isFiltered ? "light" : "outline-light"} // Change color based on filter status
        size="sm"
        disabled={disabled}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="m-0 p-2" style={{ zIndex: "10000" }}>
        {options.map((option, index) => (
          <Form.Check
            key={index}
            type="checkbox"
            label={option.display_name}
            className="mb-1 w-100"
            onChange={() => handleCheckboxChange(option)}
            checked={selectedOptions.some((selectedOption) => selectedOption.id === option.id)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownSelect;
