import React, { useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Modal, Row, Button } from "react-bootstrap";
import { associateCustomer, createCustomer, getAssociatedCustomers, getUnassociatedCustomers } from "../../API/admin";
import { useAuth } from "../../Auth/AuthContext";
import useDebounce from "../../Hooks/useDebounce"; // Assuming you have a custom hook for debouncing
import PrimaryButton from "../Buttons/PrimaryButton";  // Add this import
import AddButton from "../Buttons/AddButton";  // Add this import

const CustomerModal = ({ show, handleClose, refreshCustomers }) => {
  const { authState } = useAuth();
  const [inputValue, setInputValue] = useState("");
  const [unassociatedCustomerData, setUnassociatedCustomerData] = useState([]);
  const [associatedCustomerData, setAssociatedCustomerData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showCreateCustomerForm, setShowCreateCustomerForm] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [newCustomerEmail, setNewCustomerEmail] = useState("");
  const suggestionsRef = useRef(null);

  const debouncedInputValue = useDebounce(inputValue, 300); // Debounce input changes

  useEffect(() => {
    if (show) {
      console.log("Modal is shown. Fetching customers...");
      getUnassociatedCustomers(authState.token)
        .then((response) => {
          console.log("Received unassociated customers:", response);
          setUnassociatedCustomerData(response);
        })
        .catch((error) => {
          console.error("Error fetching unassociated customers:", error);
        });

      getAssociatedCustomers(authState.token)
        .then((response) => {
          console.log("Received associated customers:", response);
          setAssociatedCustomerData(response);
        })
        .catch((error) => {
          console.error("Error fetching associated customers:", error);
        });
    }
  }, [authState.token, show]);

  const normalizeAndValidateDomain = (domain) => {
    domain = domain.toLowerCase();

    // Remove scheme (http, https) and 'www.'
    domain = domain.replace(/^https?:\/\/(www\.)?|^www\./, "");

    // Skip validation if the domain is too short to be valid
    if (domain.length < 3 || !domain.includes(".")) {
      return { valid: false, normalizedDomain: domain };
    }

    // Extract domain and path
    const urlParts = domain.split("/");
    const domainPart = urlParts.shift();  // Remove and store the first part (domain)
    const pathPart = urlParts.join("/");  // Join the remaining parts (path)

    // Regular expression to match valid domain names
    const domainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    // Validate the domain part using the regex
    if (!domainRegex.test(domainPart)) {
      return { valid: false, normalizedDomain: domain };
    }

    // Return the cleaned domain and path
    return { valid: true, normalizedDomain: domainPart + (pathPart ? "/" + pathPart : "") };
  };

  useEffect(() => {
    if (!debouncedInputValue) return;

    const { valid, normalizedDomain } = normalizeAndValidateDomain(debouncedInputValue);

    if (!valid) {
      setSuggestions([]);
      setShowCreateCustomerForm(false);
      return;
    }

    setSuggestions(
      unassociatedCustomerData.filter((customer) =>
        normalizeAndValidateDomain(customer.domain).normalizedDomain.includes(normalizedDomain)
      )
    );

    const matchedCustomer = unassociatedCustomerData.find(
      (customer) => normalizeAndValidateDomain(customer.domain).normalizedDomain === normalizedDomain
    );

    const associatedCustomer = associatedCustomerData.find(
      (customer) => normalizeAndValidateDomain(customer.domain).normalizedDomain === normalizedDomain
    );

    if (associatedCustomer) {
      setSelectedCustomer(associatedCustomer);
      setShowCreateCustomerForm(false);
    } else if (matchedCustomer) {
      setSelectedCustomer(matchedCustomer);
      setShowCreateCustomerForm(false);
    } else {
      setSelectedCustomer(null);
      setShowCreateCustomerForm(true);
    }
  }, [debouncedInputValue, unassociatedCustomerData, associatedCustomerData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedCustomer(suggestion);
    setInputValue(suggestion.domain);
    setSuggestions([]);
    setShowCreateCustomerForm(false);
  };

  const handleCreateNewCustomer = () => {
    setShowCreateCustomerForm(true);
  };

  const handleNewCustomerNameChange = (e) => {
    setNewCustomerName(e.target.value);
  };

  const handleNewCustomerEmailChange = (e) => {
    setNewCustomerEmail(e.target.value);
  };

  const handleCreateAndAssociateCustomer = async () => {
    try {
      console.log("Creating and associating new customer...");
      const newCustomer = await createCustomer(
        {
          cust_name: newCustomerName,
          domain: inputValue,
          admin_email: newCustomerEmail
        },
        authState.token
      );
      console.log("New customer created and associated:", newCustomer);
      refreshCustomers();
      handleClose();
    } catch (error) {
      console.error("Error creating and associating customer:", error);
    }
  };

  const handleAssociateCustomer = async (customer) => {
    try {
      console.log("Associating customer:", customer);
      await associateCustomer(customer, authState.token);
      refreshCustomers();
      handleClose();
    } catch (error) {
      console.error("Error associating customer:", error);
    }
  };

  const resetForm = () => {
    setInputValue("");
    setSelectedCustomer(null);
    setShowCreateCustomerForm(false);
    setNewCustomerName("");
    setNewCustomerEmail("");
  };

  return (
    <Modal size="md" show={show} onHide={handleClose} onExited={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-4 w-100 fw-bolder bw-text-primary">Add Client</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="mt-3">Enter the client's domain.</div>
        <Row className="my-4">
          <Col>
            <Form>
              <Form.Group className="form-group" controlId="searchCustomer">
                <InputGroup>
                  <InputGroup.Text>www.</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={inputValue}
                    placeholder="Enter domain name"
                    onChange={handleInputChange}
                  />

                  {selectedCustomer ? (
                    associatedCustomerData.some(c => c.id === selectedCustomer.id) ? (
                      <PrimaryButton
                        text="Client Already Associated"
                        disabled
                      />
                    ) : (
                      <AddButton
                        text="Add Client"
                        onClick={() => handleAssociateCustomer(selectedCustomer)}
                      />
                    )
                  ) : (
                    <PrimaryButton
                      text="Create New Client"
                      onClick={handleCreateNewCustomer}
                    />
                  )}
                </InputGroup>
                {suggestions.length > 0 && (
                  <ul className="auto-complete pointer" ref={suggestionsRef}>
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.domain}
                      </li>
                    ))}
                  </ul>
                )}

                {showCreateCustomerForm && (
                  <>
                    <div className="mt-3">
                      Enter the customer's business name.
                    </div>

                    <Form.Control
                      type="text"
                      value={newCustomerName}
                      placeholder="Enter business name"
                      onChange={handleNewCustomerNameChange}
                      className="my-3"
                    />
                    <Form.Control
                      type="text"
                      value={newCustomerEmail}
                      placeholder="Enter customer's email address"
                      onChange={handleNewCustomerEmailChange}
                      className="my-3"
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="danger"
                        className="w-25 me-2"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="success"
                        className="w-25"
                        onClick={handleCreateAndAssociateCustomer}
                        disabled={newCustomerName.length < 3 || !newCustomerEmail.includes("@")}
                      >
                        Save Client
                      </Button>
                    </div>
                  </>
                )}
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerModal;
