// Components/TabContent/ImpressionTagsTab.js
import React, { useState } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { Table, Button } from "react-bootstrap";
import { copyToClipboard } from "../../Utilities/CopyToClipboard";
import { toast } from "react-toastify";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

// Filter input for text fields
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => <input value={filterValue || ""} onChange={(e) => setFilter(e.target.value || undefined)} placeholder={`Search...`} className="form-control" />;

function ImpressionTagsTab({ data, creativeData, maxRecords = 10 }) {
  // State to track selected tags
  const [selectedTags, setSelectedTags] = useState([]);

  // Function to handle checkbox change
  const handleCheckboxChange = (tag, isChecked) => {
    setSelectedTags((prevTags) => (isChecked ? [...prevTags, tag] : prevTags.filter((t) => t !== tag)));
  };

  // Function to handle copying selected tags
  const handleCopySelected = () => {
    if (selectedTags.length > 0) {
      const tagsToCopy = selectedTags.join("\n");
      copyToClipboard(tagsToCopy);
      toast.success("Selected tags copied to clipboard!");
    }
  };

  // Back up for filtering Creative Description column - transform impression tag data object to include creative description
  // const tableData = React.useMemo(
  //   () =>
  //     data.map((impressionTag) => {
  //       const creative = creativeData.find((d) => d.id === impressionTag.creative_id);
  //       return {
  //         ...impressionTag,
  //         description: creative ? creative.description : "N/A",
  //       };
  //     }),
  //   [data, creativeData]
  // );

  // Define columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "campaign_name", // Corresponds to impressionTag.campaign_name
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Booking Name",
        accessor: "booking_name", // Corresponds to impressionTag.booking_name
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Creative Description",
        accessor: "creative_name",
        Cell: ({ row }) => {
          const impressionTag = row.original;
          const creative = creativeData.find((d) => d.id === impressionTag.creative_id);
          return creative ? creative.description : "N/A";
        },
        disableSortBy: true, // Disable sorting for the description
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Tag",
        accessor: "tag",
        Cell: ({ value }) => <span>{value && value.toLowerCase()}</span>,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Options",
        accessor: "checkbox",
        disableSortBy: true,
        disableFilters: true,
        size: 50,
        Cell: ({ row }) => <input type="checkbox" onChange={(e) => handleCheckboxChange(row.original.tag, e.target.checked)} />,
      },
    ],
    [creativeData]
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter, // Default filter UI for all columns
    }),
    []
  );

  // Use the react-table hooks
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters, // Use column filters
    useSortBy // Use sorting
  );

  // Determine if we need to apply scrollable class based on the number of rows
  const isScrollable = rows.length > maxRecords;

  return (
    <div>
      {/* Add scrollable tbody when rows exceed maxRecords */}
      <Table {...getTableProps()} className="bw-table mt-5">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                // Check if the column header is "Options"
                const isOptionsColumn = column.render("Header") === "Options";
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`p-0 align-top border-bottom-0 ${isOptionsColumn ? "options-column" : ""}`}>
                    <div className="px-2 border-bottom pb-1">
                      {column.render("Header")} <span>{column.isSorted ? column.isSortedDesc ? <FaChevronDown size="0.75rem" /> : <FaChevronUp size="0.75rem" /> : ""}</span>
                    </div>
                    {/* Add a sort direction indicator */}

                    <div className={`${column.canFilter ? "p-2" : ""}`}>{column.canFilter ? column.render("Filter") : null}</div>

                    {/* Copy button */}
                    {isOptionsColumn && (
                      <Button onClick={handleCopySelected} disabled={selectedTags.length === 0} className="mt-2">
                        Copy{" "}
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z" clip-rule="evenodd" />
                          <path fill-rule="evenodd" d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {/* Apply the scrollable class to tbody if more than maxRecords rows */}
        <tbody {...getTableBodyProps()} className={isScrollable ? "scrollable-tbody" : ""}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  // Check if the column header is "Options"
                  const isOptionsColumn = cell.column.render("Header") === "Options";
                  return (
                    <td {...cell.getCellProps()} className={`tag-table-item ${isOptionsColumn ? "options-column" : ""}`}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ImpressionTagsTab;
