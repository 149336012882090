// Components/Shared/ShadedTable.js
import React from "react";
import { Card } from "react-bootstrap";
import { useTable } from "react-table";
import { shortenedNumericValue } from "../../Utilities/Utils";
import HelpTooltip from "./HelpToolTip";

const columns = [
  {
    Header: "Time",
    accessor: "time",
    className: "shaded-table-header" // Apply dark blue background with white text
  },
  {
    Header: "Sun",
    accessor: "Sun",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Mon",
    accessor: "Mon",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Tue",
    accessor: "Tue",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Wed",
    accessor: "Wed",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Thu",
    accessor: "Thu",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Fri",
    accessor: "Fri",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Sat",
    accessor: "Sat",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  },
  {
    Header: "Total",
    accessor: "Total",
    className: "shaded-table-header day-column" // Apply dark blue background with white text
  }
];

const getBackgroundColor = (value, maxValue) => {
  const minOpacity = 0.25;
  const maxOpacity = 1.0;
  const opacity = minOpacity + (value / maxValue) * (maxOpacity - minOpacity);
  return `rgba(0, 162, 232, ${opacity})`;
};

const getTextColorClass = (opacity) => {
  return opacity > 0.5 ? "light-text" : "dark-text";
};

const formatValue = (value, percent) => {
  return percent ? `${(value * 100).toFixed(1)}%` : shortenedNumericValue({ value });
};

const ShadedTable = ({ title, data, percent, helptext }) => {
  const maxValue = Math.max(
    ...data
      .filter((row) => row.time !== "Total")
      .flatMap((row) =>
        Object.entries(row)
          .filter(([key, value]) => key !== "Total" && key !== "time")
          .map(([key, value]) => value)
      )
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <Card className="overflow-hidden shaded-table">
      <Card.Header className="bw-bg-primary text-light">
        <Card.Title className="fw-bold mb-0 py-1 h6">{title}</Card.Title>
        <HelpTooltip helpTextHtml={helptext} />
      </Card.Header>
      <Card.Body className="m-0 p-0 overflow-hidden">
        <table
          {...getTableProps()}
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={column.className} // Apply the custom class
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const valueOpacity = (cell.value / maxValue).toFixed(2);
                    const isTotal = cell.column.id === "Total" || row.original.time === "Total";
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`table-cell ${isTotal ? "table-cell-total" : "table-cell-shaded"} ${getTextColorClass(valueOpacity)}`}
                        style={{
                          backgroundColor: !isTotal && cell.column.id !== "time" ? getBackgroundColor(cell.value, maxValue) : null,
                        }}
                      >
                        {cell.column.id === "time" ? cell.value : formatValue(cell.value, percent)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

export default ShadedTable;
