// Dashboards/PerformanceDashboard.js
import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import PerformanceDetail from "../Charts/PerformanceDetail";
import ShadedTable from "../Shared/ShadedTable";
import { useData } from "../../Data/GraphContext";
import { numberWithCommas } from "../../Utilities/Utils";
import SingleHorizontalBarChart from "../Charts/SingleHorizontalBarChart";

const PerformanceDashboard = () => {
  const { creativePerformanceCost, impressionHeatmap } = useData();
  const tableData = creativePerformanceCost?.table_data || [];

  return (
    <Row className="g-1 d-flex pt-2 pb-4">
      <Col xs={12} md={4}>
        <PerformanceDetail />
      </Col>

      <Col xs={12} md={4} className="px-3">
        <Card className="h-100">
          <Card.Header className="bw-bg-primary text-light fw-bold">Creative Performance Visits</Card.Header>
          <Card.Body>
            <SingleHorizontalBarChart usePrimaryColor dataKey="Total_Responses" labelKey="Creative" />
          </Card.Body>
        </Card>
      </Col>

      <Col xs={12} md={4}>
        <Card className="h-100">
          <Card.Header className="bw-bg-primary text-light fw-bold">Creative Performance Percentage</Card.Header>
          <Card.Body>
            <SingleHorizontalBarChart percent useSecondaryColor dataKey="Responses_Perc" labelKey="Creative" />
          </Card.Body>
        </Card>
      </Col>

      <Col xs={12} className="py-2">
        <Card>
          <Card.Body className="p-0">
            <Table className="mb-0">
              <thead>
                <tr>
                  <th className="bw-bg-primary text-light">Creative Name</th>
                  <th className="bw-bg-primary text-light text-center">Duration</th>
                  <th className="bw-bg-primary text-light text-center">Impressions #</th>
                  <th className="bw-bg-primary text-light text-center">Ad Spend</th>
                  <th className="bw-bg-primary text-light text-center">Total Visits</th>
                  <th className="bw-bg-primary text-light text-center">Response Rate</th>
                  <th className="bw-bg-primary text-light text-center">Cost Per Visit</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.Creative}</td>
                    <td className="text-center">{item.Duration}</td>
                    <td className="text-center">{numberWithCommas(item.Total_Impressions)}</td>
                    <td className="text-center">${item.Ad_Spend.toFixed(2)}</td>
                    <td className="text-center">{item.Total_Responses}</td>
                    <td className="text-center">{(item.Response_Rate_Percent * 100).toFixed(1)}%</td>
                    <td className="text-center">${item.Current_CPR.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>

      <Col xs={12} lg={4} className="pe-3">
        <ShadedTable
          title={"Impressions Per Daypart"}
          data={impressionHeatmap.impressions}
          helptext={`
    <strong>Day Part Timings</strong><br/>
<table class="table table-sm">
  <tbody>
    <tr><td>Breakfast</td><td>06:00 - 08:59</td></tr>
    <tr><td>Morning</td><td>09:00 - 11:59</td></tr>
    <tr><td>Lunch</td><td>12:00 - 13:59</td></tr>
    <tr><td>Afternoon</td><td>14:00 - 17:59</td></tr>
    <tr><td>Access</td><td>18:00 - 19:29</td></tr>
    <tr><td>Peak</td><td>19:30 - 22:29</td></tr>
    <tr><td>Off-peak</td><td>22:30 - 23:59</td></tr>
    <tr><td>Night</td><td>00:00 - 05:59</td></tr>
  </tbody>
</table>
<br/>
    Total number of impressions served by daypart
  `}
        />
      </Col>
      <Col xs={12} lg={4} className="px-3">
        <ShadedTable
          title={"Visits by Activating Daypart"}
          data={impressionHeatmap.webhits}
          helptext={`
    <strong>Day Part Timings</strong><br/>
<table class="table table-sm">
  <tbody>
    <tr><td>Breakfast</td><td>06:00 - 08:59</td></tr>
    <tr><td>Morning</td><td>09:00 - 11:59</td></tr>
    <tr><td>Lunch</td><td>12:00 - 13:59</td></tr>
    <tr><td>Afternoon</td><td>14:00 - 17:59</td></tr>
    <tr><td>Access</td><td>18:00 - 19:29</td></tr>
    <tr><td>Peak</td><td>19:30 - 22:29</td></tr>
    <tr><td>Off-peak</td><td>22:30 - 23:59</td></tr>
    <tr><td>Night</td><td>00:00 - 05:59</td></tr>
  </tbody>
</table>
<br/>
    Visits are adjusted back from when they actually occurred to the time an Impression was served that caused the response`}
        />
      </Col>
      <Col xs={12} lg={4} className="ps-3">
        <ShadedTable
          title={"Visits / Impressions Ratio (%)"}
          data={impressionHeatmap.response_ratio}
          percent
          helptext={`
    <strong>Day Part Timings</strong><br/>
<table class="table table-sm">
  <tbody>
    <tr><td>Breakfast</td><td>06:00 - 08:59</td></tr>
    <tr><td>Morning</td><td>09:00 - 11:59</td></tr>
    <tr><td>Lunch</td><td>12:00 - 13:59</td></tr>
    <tr><td>Afternoon</td><td>14:00 - 17:59</td></tr>
    <tr><td>Access</td><td>18:00 - 19:29</td></tr>
    <tr><td>Peak</td><td>19:30 - 22:29</td></tr>
    <tr><td>Off-peak</td><td>22:30 - 23:59</td></tr>
    <tr><td>Night</td><td>00:00 - 05:59</td></tr>
  </tbody>
</table>
<br/>
    The performance ratio of number of Impressions served against the Activating Dayparts that generated Visits.`}
        />
      </Col>
    </Row>
  );
};

export default PerformanceDashboard;
