// Data/GraphContext.js
import React, { createContext, useContext, useState } from "react";
import {
  getCreativePerformanceCost,
  getCreativePerformanceSummary,
  getImpressionHeatmap,
  getImpressionsBeforeVisit,
  getImpressionSummary,
  getPlatformCreativePerformance,
  getResponsesByRegion,
  getResponsesBySite,
  getResponsesOverTime,
  getVisitResponseTiming
} from "../API/dashboard";
import { useAuth } from "../Auth/AuthContext";

// Create a Context for the data
const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [impressionSummary, setImpressionSummary] = useState(null);
  const [impressionsBeforeVisit, setImpressionsBeforeVisit] = useState(null);
  const [responsesByRegion, setResponsesByRegion] = useState(null);
  const [responsesBySite, setResponsesBySite] = useState(null);
  const [responsesOverTime, setResponsesOverTime] = useState(null);
  const [visitResponseTiming, setVisitResponseTiming] = useState(null);
  const [platformCreativePerformance, setPlatformCreativePerformance] = useState(null);
  const [creativePerformanceSummary, setCreativePerformanceSummary] = useState(null);
  const [creativePerformanceCost, setCreativePerformanceCost] = useState(null);
  const [impressionHeatmap, setImpressionHeatmap] = useState(null);
  const [hasGraphData, setHasGraphData] = useState(false);

  const { authState } = useAuth();
  const token = authState.token;

  const resetData = () => {
    setImpressionSummary(null);
    setImpressionsBeforeVisit(null);
    setResponsesByRegion(null);
    setResponsesBySite(null);
    setResponsesOverTime(null);
    setVisitResponseTiming(null);
    setPlatformCreativePerformance(null);
    setCreativePerformanceSummary(null);
    setCreativePerformanceCost(null);
    setImpressionHeatmap(null);
    setHasGraphData(false);
  };

  const refreshData = async ({
                               clientId,
                               selectedDateRange,
                               selectedRegions,
                               selectedCampaigns,
                               selectedCreatives,
                               selectedChannels,
                               selectedWeekdays,
                               selectedDayparts,
                               selectedSites
                             }) => {
    const data = {
      client_id: clientId,
      startdate: selectedDateRange.startDate,
      enddate: selectedDateRange.endDate,
      region: selectedRegions || [0],
      campaign: selectedCampaigns || [0],
      creative: selectedCreatives || [0],
      channel: selectedChannels || [0],
      weekday: selectedWeekdays || [0],
      time_slots: selectedDayparts || [0],
      sites: selectedSites || [0]
    };

    resetData();

    try {
      const [
        fetchedImpressionSummary,
        fetchedImpressionsBeforeVisit,
        fetchedResponsesByRegion,
        fetchedResponsesBySite,
        fetchedResponsesOverTime,
        fetchedVisitResponseTiming,
        fetchedPlatformCreativePerformance,
        fetchedCreativePerformanceSummary,
        fetchedCreativePerformanceCost,
        fetchedImpressionHeatmap
      ] = await Promise.all([
        getImpressionSummary(data, token),
        getImpressionsBeforeVisit(data, token),
        getResponsesByRegion(data, token),
        getResponsesBySite(data, token),
        getResponsesOverTime(data, token),
        getVisitResponseTiming(data, token),
        getPlatformCreativePerformance(data, token),
        getCreativePerformanceSummary(data, token),
        getCreativePerformanceCost(data, token),
        getImpressionHeatmap(data, token)
      ]);

      if (fetchedImpressionSummary.summary_data) {
        setImpressionSummary(fetchedImpressionSummary);
        setImpressionsBeforeVisit(fetchedImpressionsBeforeVisit);
        setResponsesByRegion(fetchedResponsesByRegion);
        setResponsesBySite(fetchedResponsesBySite);
        setResponsesOverTime(fetchedResponsesOverTime);
        setVisitResponseTiming(fetchedVisitResponseTiming);
        setPlatformCreativePerformance(fetchedPlatformCreativePerformance);
        setCreativePerformanceSummary(fetchedCreativePerformanceSummary);
        setCreativePerformanceCost(fetchedCreativePerformanceCost);
        setImpressionHeatmap(fetchedImpressionHeatmap);
        setHasGraphData(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // console.log("Data retrieved.");
    }
  };

  return (
    <GraphContext.Provider
      value={{
        impressionSummary,
        impressionsBeforeVisit,
        responsesByRegion,
        responsesBySite,
        responsesOverTime,
        visitResponseTiming,
        platformCreativePerformance,
        creativePerformanceSummary,
        creativePerformanceCost,
        impressionHeatmap,
        refreshData,
        resetData,
        hasGraphData,
        setSelectedCustomer,
        selectedCustomer,
        selectedClient,
        setSelectedClient
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};

// Custom hook for accessing the context data
export const useData = () => {
  return useContext(GraphContext);
};
