// Components/TabContent/BookingsTab.js
import React from "react";
import FilteredTable from "../Shared/FilteredTable";  // Import the reusable FilteredTable component
import TableOptions from "../TableOptions";

function BookingsTab({ data, showEditBooking }) {
  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "Booking Name",
        accessor: "booking_name", // Corresponds to booking.booking_name
      },
      {
        Header: "Campaign",
        accessor: "campaign_name", // Corresponds to booking.campaign_name
      },
      {
        Header: "Channel",
        accessor: "channel_name", // Corresponds to booking.channel_name
      },
      {
        Header: "Booking Type",
        accessor: "booking_type", // Corresponds to booking.booking_type
        // Custom rendering for the booking type
        Cell: ({ value }) => {
          return value === 1 ? "Video" : value === 2 ? "Image" : "Unknown";
        },
        disableSortBy: true, // Disable sorting for Booking Type column
      },
      {
        Header: "Start Date",
        accessor: "start_date", // Corresponds to booking.start_date
        // Enable sorting and format date
        Cell: ({ value }) => {
          if (!value) return "N/A";
    const [year, month, day] = value.split("-");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${day}-${monthNames[parseInt(month, 10) - 1]}-${year}`;
        },
      },
      {
        Header: "End Date",
        accessor: "end_date", // Corresponds to booking.end_date
        // Enable sorting and format date
        Cell: ({ value }) => {
          if (!value) return "N/A";
    const [year, month, day] = value.split("-");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${day}-${monthNames[parseInt(month, 10) - 1]}-${year}`;
        },
      },
      {
        Header: "CPM",
        accessor: "cpm", // Corresponds to booking.cpm
        // Enable sorting and format CPM value
        Cell: ({ value }) => `$${value.toLocaleString()}`,
      },
      {
        Header: "Options",
        accessor: "options",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <TableOptions
            edit
            onEdit={() => showEditBooking(row.original)} // Pass booking data to the edit handler
          />
        ),
      },
    ],
    [showEditBooking]
  );

  return (
    <div>
      <FilteredTable columns={columns} data={data} /> {/* Use the FilteredTable component */}
    </div>
  );
}

export default BookingsTab;
