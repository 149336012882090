// toastService.js
import { toast } from "react-toastify";

const defaultOptions = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

const displayedMessages = new Set();

const notify = (type, message, options = {}) => {
  if (!displayedMessages.has(message)) {
    displayedMessages.add(message);
    toast[type](message, {
      ...defaultOptions,
      ...options,
      onClose: () => {
        displayedMessages.delete(message);
      }
    });
  }
};

export const notifySuccess = (message, options = {}) => {
  notify("success", message, options);
};

export const notifyError = (message, options = {}) => {
  notify("error", message, options);
};

export const notifyInfo = (message, options = {}) => {
  notify("info", message, options);
};

export const notifyWarning = (message, options = {}) => {
  notify("warning", message, options);
};

export const notifyCustom = (message, options = {}) => {
  notify("default", message, options);
};
