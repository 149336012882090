// src/Pages/AcceptInvitationPage.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { acceptInvitation } from "../API/auth";

const AcceptInvitationPage = () => {
  const { token } = useParams(); // Get the acceptance token from the URL
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    // Function to handle the invitation acceptance
    const acceptInvitationHandler = async () => {
      try {
        await acceptInvitation(token);
        setStatus("success");
      } catch (error) {
        setStatus("error");
      }
    };

    acceptInvitationHandler();
  }, [token]);

  return (
    <div className="accept-invitation-page">
      {status === "loading" && <p>Processing your invitation...</p>}
      {status === "success" && (
        <p>Thank you! Your invitation has been accepted successfully.</p>
      )}
      {status === "error" && (
        <p>Sorry, there was a problem accepting the invitation. Please try again later.</p>
      )}
    </div>
  );
};

export default AcceptInvitationPage;
