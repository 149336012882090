// Components/TabContent/CreativesTab.js
import React from "react";
import FilteredTable from "../Shared/FilteredTable";  // Import the reusable FilteredTable component
import TableOptions from "../TableOptions";          // Import TableOptions for edit functionality

function CreativesTab({ data, showEditCreative }) {
  const capitalizeFirstLetter = (str) => {
    if (!str || str.length === 0) return str;  // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Creative Name",
      //   width: "20%",
      //   accessor: "key_number"  // Corresponds to creative.key_number
      //   // Enable sorting for Key Number
      // }, // *Key number is being removed
      {
        Header: "Type",
        accessor: "creative_type_label", // Corresponds to creative.creative_type_label
        width: "20%",
        // Enable sorting and capitalize the first letter
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Description",
        accessor: "description", // Corresponds to creative.description
        width: "40%",
        disableSortBy: true, // Disable sorting for Description
      },
      {
        Header: "Format",
        accessor: "creative_format", // Corresponds to creative.creative_format
        width: "15%",
        // Enable sorting for Format
      },
      {
        Header: "Options",
        accessor: "options",
        width: "10%",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <TableOptions
            edit
            onEdit={() => showEditCreative(row.original)} // Pass creative data to the edit handler
          />
        ),
      },
    ],
    [showEditCreative]
  );

  return (
    <div>
      <FilteredTable columns={columns} data={data} /> {/* Use the FilteredTable component */}
    </div>
  );
}

export default CreativesTab;
