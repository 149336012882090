// Components/Shared/HelpToolTip.js
import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaInfoCircle} from "react-icons/fa";

const HelpTooltip = ({helpText, helpTextHtml, placement = "top", color = "inherit"}) => {
    // console.log("Rendering HelpTooltip with content");
    const content = helpTextHtml ? (
        <div dangerouslySetInnerHTML={{__html: helpTextHtml}}/>
    ) : (
        <div>{helpText}</div>
    );

    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id={`tooltip-${placement}`}>{content}</Tooltip>}
        >
            <div style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer'}}>
                <FaInfoCircle style={{color}}/>
            </div>
        </OverlayTrigger>
    );
};

export default HelpTooltip;
