import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginForm from "../Components/LoginForm";
import LoginBackground from "../Images/login_background.svg";
import InfinitumLogo from "../Images/white_text_logo.svg";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";

const LoginPage = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.isAuth) {
      navigate("/home", { replace: true });
    }
  }, [authState, navigate]);

  const backgroundStyle = {
    backgroundImage: `url(${LoginBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw"
  };

  return (
    <div style={backgroundStyle}>
      <Container fluid className="p-0">
        <Row className="min-vh-100 m-0">
          <Col xs={12} md={6} className="d-flex flex-column">
            <div className="h-50 m-5">
              <img src={InfinitumLogo} alt={"Infinitum"} width={"250px"} />
            </div>
            <div className="h-50 d-flex">
              <LoginForm />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
