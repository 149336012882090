// Utilities/ExportAllDataToExcel.js
import React from "react";
import * as XLSX from "xlsx";
import { useData } from "../Data/GraphContext";
import { Button } from "react-bootstrap";

const ExportAllDataToExcel = () => {
  const {
    impressionSummary,
    impressionsBeforeVisit,
    responsesByRegion,
    responsesBySite,
    responsesOverTime,
    visitResponseTiming,
    platformCreativePerformance,
    creativePerformanceSummary,
    creativePerformanceCost,
    impressionHeatmap
  } =
    useData();

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const dataObjects = [
      { sheetName: "ImpSummary", summaryData: impressionSummary?.summary_data || [], tableData: [] },
      {
        sheetName: "ImpsBeforeVisit",
        summaryData: impressionsBeforeVisit?.summary_data || [],
        tableData: impressionsBeforeVisit?.table_data || []
      },
      { sheetName: "RespByRegion", summaryData: [], tableData: responsesByRegion?.table_data || [] },
      { sheetName: "RespBySite", summaryData: [], tableData: responsesBySite?.table_data || [] },
      {
        sheetName: "RespOverTime",
        summaryData: responsesOverTime?.correlation || [],
        tableData: responsesOverTime?.table_data || []
      },
      { sheetName: "VisitRespTiming", summaryData: visitResponseTiming?.summary_data || [], tableData: [] },
      { sheetName: "CtvPerformance", summaryData: [], tableData: platformCreativePerformance?.table_data || [] },
      { sheetName: "CtvSummary", summaryData: [], tableData: creativePerformanceSummary?.table_data || [] },
      { sheetName: "CtvCost", summaryData: [], tableData: creativePerformanceCost?.table_data || [] },
      // Handling impressionHeatmap separately as it has a different structure
      { sheetName: "Heatmap_Imps", summaryData: impressionHeatmap?.impressions || [], tableData: [] },
      { sheetName: "Heatmap_Visits", summaryData: impressionHeatmap?.webhits || [], tableData: [] },
      { sheetName: "Heatmap_Resp_Ratio", summaryData: impressionHeatmap?.response_ratio || [], tableData: [] }
    ];

    dataObjects.forEach((dataObject) => {
      const { sheetName, summaryData, tableData } = dataObject;

      if (summaryData && summaryData.length > 0) {
        const summarySheet = XLSX.utils.json_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(workbook, summarySheet, `${sheetName}_Smry`);
      }

      if (tableData && tableData.length > 0) {
        const tableSheet = XLSX.utils.json_to_sheet(tableData);
        XLSX.utils.book_append_sheet(workbook, tableSheet, `${sheetName}_Table`);
      }
    });

    // Write the workbook and create a download link
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(dataBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Alldata.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Button onClick={handleExport} variant="outline-light" size="sm">
      Export All
    </Button>
  );
};

export default ExportAllDataToExcel;
