// Pages/ResetPasswordPage.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import { resetNewPassword, verifyResetToken } from "../API/auth";
import { notifyError, notifySuccess } from "../Utilities/ToastService";

// Utility to parse query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordPage = () => {
  const query = useQuery(); // Get the query parameters
  const token = query.get("token"); // Extract the 'token' from the query string
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);

  useEffect(() => {
    console.log("Token received from URL:", token);

    // Verify the token when the page loads
    const checkToken = async () => {
      if (!token) {
        console.log("No token provided");
        notifyError("No token provided.");
        navigate("/"); // Redirect to login if no token is provided
        return;
      }

      try {
        console.log("Verifying token...");
        await verifyResetToken(token); // Verify the token
        console.log("Token is valid");
        setValidToken(true);
      } catch (error) {
        console.error("Token verification failed:", error);
        notifyError("Invalid or expired reset token.");
        navigate("/"); // Redirect to login if token is invalid
      }
    };

    checkToken();
  }, [token, navigate]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      console.log("Passwords do not match");
      notifyError("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      console.log("Resetting password...");
      await resetNewPassword({ token, newPassword });
      console.log("Password reset successful");
      notifySuccess("Password reset successful.");
      navigate("/"); // Redirect to login after successful reset
    } catch (error) {
      console.error("Password reset failed:", error);
      notifyError("Failed to reset password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!validToken) {
    console.log("Waiting for token verification...");
    return <p>Loading...</p>; // Optionally, show a loading message or spinner while verifying the token
  }

  return (
    <Container className="mt-5">
      <h2>Reset Your Password</h2>
      <Form onSubmit={handleResetPassword}>
        <Form.Group>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="mt-3"
          disabled={loading}
        >
          {loading ? "Resetting..." : "Reset Password"}
        </Button>
      </Form>
    </Container>
  );
};

export default ResetPasswordPage;
