// Hooks/useTagData.js
import { useCallback, useEffect, useState } from "react";
import { getAssociatedCustomers } from "../API/admin";
import {
  getCampaignsForCustomer,
  getBookingsForCustomer,
  getCreativesForCustomer,
  getImpressionTagsForCustomer,
  getSiteTagsForCustomer,
  getChannels,
  saveBookingCreatives
} from "../API/managetags";
import { notifyError } from "../Utilities/ToastService";
import { useAuth } from "../Auth/AuthContext";

export const useTagData = (currentCustomerId, setCurrentCustomerId) => {
  const { authState } = useAuth();
  const [customerData, setCustomerData] = useState([]);
  const [customerContent, setCustomerContent] = useState({
    creativesData: [],
    siteTagsData: [],
    campaignsData: [],
    impressionTagsData: [],
    bookingsData: []
  });
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [channels, setChannels] = useState([]); // Add state for channels
  const [modals, setModals] = useState({
    customerModal: { show: false, data: null },
    creativeModal: { show: false, data: null },
    siteTagModal: { show: false, data: null },
    campaignModal: { show: false, data: null },
    bookingModal: { show: false, data: null },
    impressionTagModal: { show: false, data: null }
  }); // Ensure modals is initialized

  const updateCustomerContent = useCallback(async (customerId) => {
    try {
      console.debug("Going to fetch all customer data");
      const data = await fetchAllCustomerData(customerId);
      setCustomerContent(data);
    } catch (error) {
      console.error("Error in fetching data: ", error);
    }
  }, [customerData, authState.token]);

  useEffect(() => {
    if (selectedCustomerId) {
      updateCustomerContent(selectedCustomerId);
    }
  }, [selectedCustomerId, updateCustomerContent]);

  const fetchAllCustomerData = async (customerId, retries = 5, delay = 500) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      console.debug("customerData: ", customerData);
      const customer = customerData.find((c) => c.id === customerId);
      if (!customer) throw new Error(`Customer with ID ${customerId} not found`);

      const [creatives, siteTags, campaigns, bookings, impressionTags] = await Promise.all([
        getCreativesForCustomer(customer, authState.token),
        getSiteTagsForCustomer(customer, authState.token),
        getCampaignsForCustomer(customer, authState.token),
        getBookingsForCustomer(customer, authState.token),
        getImpressionTagsForCustomer(customer, authState.token)
      ]);

      return {
        creativesData: creatives,
        siteTagsData: siteTags,
        campaignsData: campaigns,
        bookingsData: bookings,
        impressionTagsData: impressionTags
      };
    } catch (error) {
      if (retries > 0) {
        await sleep(delay);
        return fetchAllCustomerData(customerId, retries - 1, delay);
      } else {
        notifyError("Unable to retrieve data. Please try again.");
        throw error;
      }
    }
  };

  const fetchChannels = async () => {
    try {
      const data = await getChannels(authState.token); // Use the new function
      setChannels(data);
    } catch (error) {
      console.error("Error fetching channels: ", error);
    }
  };


  const refreshCustomers = useCallback((retainSelectedCustomerId) => {
    getAssociatedCustomers(authState.token)
      .then((response) => {
        const sortedCustomers = response.sort((a, b) => a.cust_name.localeCompare(b.cust_name));
        setCustomerData(sortedCustomers);
        setSelectedCustomerId(retainSelectedCustomerId || currentCustomerId || sortedCustomers[0]?.id);
      })
      .catch((error) => {
        console.error("Error getting associated customers: ", error);
      });
  }, [authState.token, currentCustomerId]);

  useEffect(() => {
    refreshCustomers(selectedCustomerId);
    fetchChannels(); // Fetch channels when component mounts
  }, [refreshCustomers]);

  useEffect(() => {
    if (selectedCustomerId) {
      updateCustomerContent(selectedCustomerId);
    }
  }, [selectedCustomerId, customerData]);
  const saveBookingCreativeCombinations = async (campaignId, combinations) => {
    try {
      await saveBookingCreatives(campaignId, combinations, authState.token);
      // Refresh data or provide feedback as needed
    } catch (error) {
      console.error("Error saving booking creatives: ", error);
      notifyError("Unable to save booking creatives. Please try again.");
    }
  };

  return {
    customerData,
    customerContent,
    selectedCustomerId,
    setSelectedCustomerId,
    updateCustomerContent,
    refreshCustomers,
    channels,
    modals,
    saveBookingCreativeCombinations,
    handleShowModal: (type, data) => setModals({ ...modals, [type]: { show: true, data } }),
    handleCloseModal: (type) => setModals({ ...modals, [type]: { show: false, data: null } })
  };
};
