import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import { useData } from "../../Data/GraphContext";
import HelpTooltip from "../Shared/HelpToolTip";

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, LineController);

const VisitsOverTime = React.memo(() => {
  const { responsesOverTime } = useData();

  const rootStyles = getComputedStyle(document.documentElement);
  const secondaryColor = rootStyles.getPropertyValue("--secondary-color").trim();
  const primaryColor = rootStyles.getPropertyValue("--primary-color").trim();
  const tintColor = rootStyles.getPropertyValue("--highlight-color").trim();

  const ResponseOverTimeChart = React.memo(() => {
    const { data, options } = useMemo(() => {
      const tableData = responsesOverTime?.table_data || [];
      const labels = tableData.map((item) => item.Date);
      const impressionsData = tableData.map((item) => item.Total_Impressions);
      const sameDayData = tableData.map((item) => item.Same_Day);
      const totalResponsesData = tableData.map((item) => item.Total_Responses);
      const meta_data = responsesOverTime?.meta_data;

      const data = {
        labels,
        datasets: [
          {
            type: "bar",
            label: "Impressions",
            data: impressionsData,
            backgroundColor: secondaryColor,
            yAxisID: "y1",
            order: 1,
            z: 1
          },
          {
            type: "line",
            label: "Same Day",
            data: sameDayData,
            borderColor: primaryColor,
            backgroundColor: primaryColor,
            yAxisID: "y2",
            order: 0,
            z: 5,
            pointRadius: 0, // Add this line
            pointHoverRadius: 0 // Add this line
          },
          {
            type: "line",
            label: "All Responses",
            data: totalResponsesData,
            borderColor: tintColor,
            backgroundColor: tintColor,
            yAxisID: "y2",
            order: 0,
            z: 10,
            pointRadius: 0, // Add this line
            pointHoverRadius: 0 // Add this line
          }
        ]
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 8,
              padding: 20 // Add padding to prevent overlap
            }
          }
        },
        scales: {
          y1: {
            type: "linear",
            position: "left",
            beginAtZero: true,
            max: meta_data.max_y_l,
            title: {
              display: true,
              text: "Impressions"
            }
          },
          y2: {
            type: "linear",
            position: "right",
            beginAtZero: true,
            max: meta_data.max_y_lr,
            title: {
              display: true,
              text: "Visits"
            },
            grid: {
              drawOnChartArea: false // Only want the grid lines for one axis to show up
            }
          }
        }
      };

      return { data, options };
    }, [responsesOverTime, secondaryColor, primaryColor, tintColor]);

    return <Bar data={data} options={options} />;
  });

  return (
    <Card className="h-100">
      <Card.Header className="bw-bg-primary text-light d-flex justify-content-between">
        <div className="fw-bold">Visits Over Time</div>
        <HelpTooltip
          helpText="Impressions vs. Visits.  Same Day counts visits with 24 hours of an ad impression serve.  All Responses counts total Visits over the 7 days (24hr periods) following the day and time of an ad impression. "></HelpTooltip>
        {/* <ExportToExcel data={responsesOverTime.table_data} fileName={"responses-over-time"} /> */}
      </Card.Header>
      <Card.Body>
        <Row className="h-100">
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <div
              className="combo-chart-container d-flex justify-content-center align-items-center w-100">{responsesOverTime ?
              <ResponseOverTimeChart /> : <div>Loading...</div>}</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});

export default VisitsOverTime;
