// /Components/Shared/DropDownSingleSelect.js
import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Form } from "react-bootstrap";

const SingleOptionSelect = ({ title, options, onApply, disabled, reset }) => {
  const [selectedOption, setSelectedOption] = useState({ id: 0, display_name: "All" });
  const previousSelectedOption = useRef(selectedOption); // Track previous selection

  // Handles radio button change
  const handleOptionChange = (option) => {
    if (option.id === 0) {
      // If "All" is selected, select "All" and unselect others
      setSelectedOption({ id: 0, display_name: "All" });
    } else {
      // Otherwise, select the clicked option and unselect "All"
      setSelectedOption(option);
    }
  };

  // Guard useEffect to prevent unnecessary API calls
  useEffect(() => {
    if (JSON.stringify(selectedOption) !== JSON.stringify(previousSelectedOption.current)) {
      previousSelectedOption.current = selectedOption; // Update previous state

      // Call the onApply callback, passing the selection as an array to maintain consistency.
      onApply(title, [selectedOption.id]);
    }
  }, [selectedOption, title, onApply]);

  // Reset logic
  useEffect(() => {
    if (reset) {
      // Reset selection to "All" when reset is true
      setSelectedOption({ id: 0, display_name: "All" });
    }
  }, [reset]);


  // Ensure options is always an array before mapping
  const validOptions = Array.isArray(options) ? options : [];

  return (
    <Dropdown className="me-3">
      <Dropdown.Toggle
        id="single-option-dropdown"
        className="px-3"
        variant={selectedOption.id !== 0 ? "light" : "outline-light"} // Change color based on selection status
        size="sm"
        disabled={disabled}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="m-0 p-2" style={{ zIndex: "10000" }}>
        {validOptions.map((option, index) => (
          <Form.Check
            key={index}
            type="radio"
            label={option.display_name}
            className="mb-1 w-100"
            onChange={() => handleOptionChange(option)}
            checked={selectedOption.id === option.id}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SingleOptionSelect;
