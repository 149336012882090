import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useData } from "../../Data/GraphContext";

const PerformanceDetail = () => {
  const { platformCreativePerformance } = useData();
  const tableData = platformCreativePerformance?.table_data || [];

  return (
    <Card>
      <Card.Header className="bw-bg-primary text-light fw-bold">Creative Performance by Channel</Card.Header>
      <Card.Body>
        <Row className="d-flex">
          <Col xs={12} md={4} className="flex-grow-1">
            <Table>
              <thead>
                <tr>
                  <th className="">Channel</th>
                  <th className="">Creative</th>
                  <th className="text-center">Impressions %</th>
                  <th className="text-center">Visits %</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((d, index) => (
                  <tr key={index}>
                    <td className="">{d.Platform || "N/A"}</td>
                    <td className="">{d.Creative}</td>
                    <td className="text-center">{(d.Impressions_Perc * 100).toFixed(1)}%</td>
                    <td className="text-center">{(d.Responses_Perc * 100).toFixed(1)}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PerformanceDetail;
