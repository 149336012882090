import React, { useState, useMemo } from "react";
import { useFilters, useSortBy, useTable } from "react-table";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { copyToClipboard } from "../../Utilities/CopyToClipboard";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

// Default filter input component
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => <input value={filterValue || ""} onChange={(e) => setFilter(e.target.value || undefined)} placeholder={`Search...`} className="form-control" />;

function FilteredTable({ columns, data, maxRecords = 10, isCopyTag = false }) {
  const [selectedTags, setSelectedTags] = useState([]);

  // Handle checkbox selection
  const handleCheckboxChange = (tag, isChecked) => {
    setSelectedTags((prevTags) => (isChecked ? [...prevTags, tag] : prevTags.filter((t) => t !== tag)));
  };

  // Function to copy a single tag to the clipboard
  const copyContent = (text) => {
    console.log("inside copy function");
    copyToClipboard(text);
    toast.success("Copied to clipboard!");
  };

  // Copy all selected tags
  const handleCopySelected = () => {
    if (selectedTags.length > 0) {
      const tagsToCopy = selectedTags.join("\n");
      copyToClipboard(tagsToCopy);
      toast.success("Selected tags copied to clipboard!");
    }
  };

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter, // Provide a default filter UI for all columns
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters, // Enable column filters
    useSortBy // Enable sorting
  );

  const isScrollable = rows.length > maxRecords;

  return (
    <div className="filtered-table-container">
      <Table {...getTableProps()} className="mt-3 bw-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const isOptionsColumn = column.render("Header") === "Options";
                const isCopyColumn = column.render("Header") === "";
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`h-100 align-top p-0 border-bottom-0 ${isOptionsColumn || isCopyColumn ? "options-column" : ""}`}>
                    <div className="px-2 border-bottom pb-1">
                      {column.render("Header")} <span className="d-inline-block ms-1">{column.isSorted ? column.isSortedDesc ? <FaChevronDown size="0.75rem" /> : <FaChevronUp size="0.75rem" /> : ""}</span>
                    </div>
                    {column.canFilter && <div className="p-2">{column.render("Filter")}</div>}
                    {isCopyColumn && isCopyTag && (
                      <Button onClick={handleCopySelected} disabled={selectedTags.length === 0} className="mt-2">
                        Copy{" "}
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z" clip-rule="evenodd" />
                          <path fill-rule="evenodd" d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className={isScrollable ? "scrollable-tbody" : ""}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const isOptionsColumn = cell.column.render("Header") === "Options";
                  const isCopyColumn = cell.column.render("Header") === "";
                  return (
                    <td {...cell.getCellProps()} className={isOptionsColumn || isCopyColumn ? "options-column" : ""}>
                      {isCopyColumn && isCopyTag ? <input type="checkbox" onChange={(e) => handleCheckboxChange(row.original.tag, e.target.checked)} /> : <span>{cell.render("Cell")}</span>}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default FilteredTable;
