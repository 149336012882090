// Components/Button/CreativeTypeButtonGroup.js
import React, { useEffect, useState } from "react";
import { MdVideocam } from "react-icons/md";
import { FaImage } from "react-icons/fa";

function CreativeTypeButtonGroup({ onTypeChange, selectedType }) {
  const [selectedCreativeType, setSelectedCreativeType] = useState(selectedType === 2 ? "image" : "video");

  const handleCreativeTypeSelection = (type_int, e) => {
    e.preventDefault(); // Prevent the form from submitting
    if (type_int === 1) {
      setSelectedCreativeType("video");
      onTypeChange(1);
      return;
    }

    if (type_int === 2) {
      setSelectedCreativeType("image");
      onTypeChange(2);
      return;
    }
  };

  useEffect(() => {
    if (selectedType === 1) {
      handleCreativeTypeSelection(1, {
        preventDefault: () => {
        }
      });
    } else if (selectedType === 2) {
      handleCreativeTypeSelection(2, {
        preventDefault: () => {
        }
      });
    }
  }, [selectedType]);

  return (
    <>
      <div className="tiny-heading mb-2">Type</div>
      <div className="d-flex gap-3 align-items-center">
        <button className={`bw-button button-rounded ${selectedCreativeType === "video" ? "bw-button-primary--alt" : "bw-button-secondary"}`} onClick={(e) => handleCreativeTypeSelection(1, e)}>
          Video
        </button>

        <button className={`bw-button button-rounded ${selectedCreativeType === "image" ? "bw-button-primary--alt" : "bw-button-secondary"}`} onClick={(e) => handleCreativeTypeSelection(2, e)}>
          Image
        </button>
      </div>
    </>
  );
}

export default CreativeTypeButtonGroup;
