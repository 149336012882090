import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const CustomDateRangePicker = ({ setSelectedDateRange }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleChangeDate = (item) => {
    setState([item.selection]);
  };

  useEffect(() => {
    setSelectedDateRange(state[0]);
  }, [setSelectedDateRange, state]);

  const staticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: subDays(new Date(), 1),
        endDate: subDays(new Date(), 1),
      }),
    },
    {
      label: "Day Before Yesterday",
      range: () => ({
        startDate: subDays(new Date(), 2),
        endDate: subDays(new Date(), 2),
      }),
    },
    {
      label: "Previous Week",
      range: () => ({
        startDate: startOfWeek(subWeeks(new Date(), 1)),
        endDate: endOfWeek(subWeeks(new Date(), 1)),
      }),
    },
    {
      label: "Previous Month",
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    {
      label: "Previous Year",
      range: () => ({
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      }),
    },
    {
      label: "Last 2 Days",
      range: () => ({
        startDate: subDays(new Date(), 2),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: subDays(new Date(), 90),
        endDate: new Date(),
      }),
    },
    {
      label: "Last 1 Year",
      range: () => ({
        startDate: subYears(new Date(), 1),
        endDate: new Date(),
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      }),
    },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
      }),
    },
  ].map((range) => ({
    ...range,
    isSelected: () => false,
  }));

  return (
    <div>
      <DateRangePicker
        onChange={(item) => handleChangeDate(item)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        preventSnapRefocus={true}
        calendarFocus="backwards"
        staticRanges={staticRanges}
        inputRanges={[]}
      />
    </div>
  );
};

export default CustomDateRangePicker;
