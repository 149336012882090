import { format } from "date-fns";
import numeral from "numeral";

export const shortenedNumericValue = ({ value }) => {
  const formattedValue = numeral(value).format("0.[0]a"); // This will format numbers as 23.3k, 1.23M, etc.
  return formattedValue;
};

export const numberWithCommas = (number, decimalPlaces = 0) => {
  const fixedNumber = Number(number).toFixed(decimalPlaces);
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(fixedNumber);
};

export const formatDateRange = (startDate, endDate) => {
  const formattedStartDate = format(new Date(startDate), "dd MMM yyyy");
  const formattedEndDate = format(new Date(endDate), "dd MMM yyyy");
  return `${formattedStartDate} - ${formattedEndDate}`;
};
