// Components/Modals/CustomerModal.js
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { associateCustomer, createCustomer, deleteCustomer, getAssociatedCustomers, getUnassociatedCustomers } from "../../API/admin";
import PrimaryButton from "../Buttons/PrimaryButton";
import AdminButton from "../Buttons/AdminButton";
import EditClientModal from "../Modals/EditClientModal";
import ConfirmRemoveModal from "../Modals/ConfirmRemoveModal";
import NotificationModal from "../Modals/NotificationModal";
import AdminNotification from "../Alerts/AdminNotification";
import { useAuth } from "../../Auth/AuthContext";
import useDebounce from "../../Hooks/useDebounce";

const ClientsTab = () => {
  const [modals, setModals] = useState({
    edit: false,
    added: false,
    remove: false,
    error: false,
    associateRequest: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [alerts, setAlerts] = useState({
    updateSuccess: false,
    removeSuccess: false,
  });
  const [inputValues, setInputValues] = useState({
    domain: "",
    name: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: false,
    domain: false,
    name: false,
  });
  const [unassociatedCustomerData, setUnassociatedCustomerData] = useState([]);
  const [associatedCustomerData, setAssociatedCustomerData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showCreateCustomerForm, setShowCreateCustomerForm] = useState(false);
  const suggestionsRef = useRef(null);
  const [currentClient, setCurrentClient] = useState({});
  const { authState } = useAuth();
  const { role } = authState;

  const ref = useRef();

  const handleShowModal = (modal, client) => {
    setModals((prev) => ({
      ...prev,
      [modal]: true,
    }));
    setCurrentClient(client);
  };

  const handleCloseModal = (modal) => {
    setModals((prev) => ({
      ...prev,
      [modal]: false,
    }));
    setCurrentClient({});
  };

  const handleCloseAlert = (alert) => {
    setAlerts((prev) => ({
      ...prev,
      [alert]: false,
    }));
  };

  const handleShowError = (message) => {
    setModals((prev) => ({
      ...prev,
      edit: false,
      error: true,
    }));
    setErrorMessage(message);
  };

  const handleScrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const checkExistingClient = (domain) => {
    const allClients = associatedCustomerData.concat(unassociatedCustomerData);
    return allClients.some((client) => normalizeAndValidateDomain(client.domain).normalizedDomain === normalizeAndValidateDomain(domain).normalizedDomain);
  };

  const debouncedInputValue = useDebounce(inputValues.domain, 300); // Debounce input changes

  const normalizeAndValidateDomain = (domain) => {
    domain = domain.toLowerCase();

    // Remove scheme (http, https) and 'www.'
    domain = domain.replace(/^https?:\/\/(www\.)?|^www\./, "");

    // Skip validation if the domain is too short to be valid
    if (domain.length < 3 || !domain.includes(".")) {
      return { valid: false, normalizedDomain: domain };
    }

    // Extract domain and path
    const urlParts = domain.split("/");
    const domainPart = urlParts.shift(); // Remove and store the first part (domain)
    const pathPart = urlParts.join("/"); // Join the remaining parts (path)

    // Regular expression to match valid domain names
    const domainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    // Validate the domain part using the regex
    if (!domainRegex.test(domainPart)) {
      return { valid: false, normalizedDomain: domain };
    }

    // Return the cleaned domain and path
    return { valid: true, normalizedDomain: domainPart + (pathPart ? "/" + pathPart : "") };
  };

  const validateFormInputs = (inputValues) => {
    let errors = {
      email: false,
      domain: false,
      name: false,
    };

    // Validate email
    if (!inputValues.email.includes("@") || inputValues.email.trim().length === 0) {
      errors.email = true;
    }

    // Validate domain
    const domainInput = normalizeAndValidateDomain(inputValues.domain);
    if (!domainInput.valid || inputValues.domain.trim().length === 0) {
      errors.domain = true;
    }

    // Validate name
    if (inputValues.name.trim().length === 0) {
      errors.name = true;
    }

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSuggestionClick = (suggestion) => {
    setSuggestions([]);
    setSelectedCustomer(suggestion);
    setInputValues((prev) => ({ ...prev, domain: suggestion.domain }));
    setShowCreateCustomerForm(false);
  };

  const handleUpdateClient = () => {
    handleCloseModal("edit");

    fetchClients();

    setTimeout(() => {
      setAlerts((prev) => ({
        ...prev,
        updateSuccess: true,
      }));
    }, 300);
  };

  const handleRemoveClient = async (clientId) => {
    try {
      await deleteCustomer(clientId, authState.token);
      console.log("Client deleted successfully");

      handleCloseModal("remove");
      await fetchClients();
      setTimeout(() => {
        setAlerts((prev) => ({
          ...prev,
          removeSuccess: true,
        }));
      }, 300);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error deleting client:", error.message);
      }
    }
  };

  const handleCreateAndAssociateCustomer = async () => {
    try {
      console.log("Creating and associating new customer...");
      const newCustomer = await createCustomer(
        {
          cust_name: inputValues.name,
          domain: inputValues.domain,
          admin_email: inputValues.email,
        },
        authState.token
      );
      console.log("New customer created and associated:", newCustomer);

      setCurrentClient((prev) => ({
        ...prev,
        name: inputValues.name,
      }));

      setInputValues({
        domain: "",
        name: "",
        email: "",
      });

      fetchClients();

      setTimeout(() => {
        setModals((prev) => ({
          ...prev,
          added: true,
        }));
      }, 200);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error creating and associating customer:", error.message);
      }
    }
  };

  const handleAssociateCustomer = async (customer) => {
    try {
      console.log("Associating customer:", customer);
      await associateCustomer(customer, authState.token);

      setCurrentClient({ domain: customer.domain, name: customer.cust_name, email: customer.admin_email });

      setInputValues((prev) => ({
        ...prev,
        domain: "",
      }));

      if (role === "ROLE_AGENCY" || role === "ROLE_AENCY_ADMIN") {
        setModals((prev) => ({
          ...prev,
          associateRequest: true,
        }));
      }

      if (role === "ROLE_BROADCASTER" || role === "ROLE_BROADCASTER_ADMIN") {
        setTimeout(() => {
          setModals((prev) => ({
            ...prev,
            added: true,
          }));
        }, 200);
      }

      fetchClients();
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error associating client:", error.message);
      }
    }
  };

  const fetchClients = useCallback(async () => {
    try {
      const associatedData = await getAssociatedCustomers(authState.token);
      const unassociatedData = await getUnassociatedCustomers(authState.token);
      setAssociatedCustomerData(associatedData);
      setUnassociatedCustomerData(unassociatedData);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  }, [authState.token]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    if (selectedCustomer) {
      setSuggestions([]);
      console.log(suggestions);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (!debouncedInputValue) {
      setSuggestions([]);
      setShowCreateCustomerForm(false);
      return;
    }

    // Always perform partial matching
    const normalizedInput = debouncedInputValue.toLowerCase().replace(/^https?:\/\/(www\.)?|^www\./, "");
    const filteredSuggestions = unassociatedCustomerData.filter((customer) => customer.domain.toLowerCase().includes(normalizedInput));

    if (!selectedCustomer) {
      setSuggestions(filteredSuggestions);
    }

    // Full domain validation
    const { valid, normalizedDomain } = normalizeAndValidateDomain(debouncedInputValue);

    const matchedCustomer = unassociatedCustomerData.find((customer) => normalizeAndValidateDomain(customer.domain).normalizedDomain === normalizedDomain);

    const associatedCustomer = associatedCustomerData.find((customer) => normalizeAndValidateDomain(customer.domain).normalizedDomain === normalizedDomain);

    if (associatedCustomer) {
      setSelectedCustomer(associatedCustomer);
      setShowCreateCustomerForm(false);
    } else if (matchedCustomer) {
      setSelectedCustomer(matchedCustomer);
      setShowCreateCustomerForm(false);
    } else {
      setSelectedCustomer(null);
      if (normalizeAndValidateDomain(debouncedInputValue).valid && !selectedCustomer) {
        setShowCreateCustomerForm(true);
      } else setShowCreateCustomerForm(false);
    }
  }, [debouncedInputValue, unassociatedCustomerData, associatedCustomerData, selectedCustomer]);

  useEffect(() => {
    if (alerts.removeSuccess || alerts.updateSuccess) {
      handleScrollToBottom();
    }
  }, [alerts]);

  return (
    <>
      <div className="fs-4 w-100 fw-bolder bw-text-primary mt-4">Add client</div>
      <div style={{ maxWidth: "28rem" }}>
        <Form className="mt-3">
          <Form.Group className="form-group" controlId="searchCustomer">
            <Form.Label className={`${formErrors.domain && "text-danger"}`}>Domain</Form.Label>
            <InputGroup>
              <InputGroup.Text>www.</InputGroup.Text>
              <Form.Control type="text" value={inputValues.domain} name="domain" onChange={handleInputChange} />

              {selectedCustomer ? (
                associatedCustomerData.some((c) => c.id === selectedCustomer.id) ? (
                  <PrimaryButton
                    text="Confirm"
                    onClick={() =>
                      setModals((prev) => ({
                        ...prev,
                        error: true,
                      }))
                    }
                  />
                ) : (
                  <PrimaryButton text="Confirm" onClick={() => handleAssociateCustomer(selectedCustomer)} />
                )
              ) : null}
            </InputGroup>
            {suggestions.length > 0 && (
              <ul className="auto-complete pointer" ref={suggestionsRef}>
                {suggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion.domain}
                  </li>
                ))}
              </ul>
            )}

            {showCreateCustomerForm && (
              <>
                <Form.Group className="mt-4">
                  <Form.Label className={`${formErrors.name && "text-danger"}`}>Name</Form.Label>
                  <Form.Control type="text" name="name" value={inputValues.name} onChange={(e) => handleInputChange(e)} className={`${formErrors.name && "border-danger"}`} />
                  {formErrors.name && (
                    <div className="text-danger fs-7" style={{ position: "absolute" }}>
                      Please provide a name.
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label className={`${formErrors.email && "text-danger"}`}>Email address</Form.Label>
                  <Form.Control type="text" name="email" value={inputValues.email} onChange={(e) => handleInputChange(e)} className={`${formErrors.email && "border-danger"}`} />
                  {formErrors.email && (
                    <div className="text-danger fs-7" style={{ position: "absolute" }}>
                      Invalid email address.
                    </div>
                  )}
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <PrimaryButton text="Confirm" onClick={handleCreateAndAssociateCustomer} />
                </div>
              </>
            )}
          </Form.Group>
        </Form>
      </div>
      <div className="mt-5">
        <div className="border-bottom pb-1 mb-2">
          <div className="fs-4 w-100 fw-bolder bw-text-primary mt-4">Clients</div>
        </div>
        <Table responsive>
          <tbody className="admin-table">
            {associatedCustomerData &&
              associatedCustomerData.map((client) => (
                <tr key={client.id}>
                  <td className="table-col-1">
                    <div className="d-flex align-items-center gap-3">
                      {client.cust_name}
                      {role !== "ROLE_BROADCASTER" && role !== "ROLE_BROADCASTER_ADMIN" && client.status !== "active" && (
                        <Badge bg="secondary" className="fw-medium">
                          Pending
                        </Badge>
                      )}
                    </div>
                  </td>
                  <td className="table-col-1">{client.domain}</td>
                  <td className="table-col-1">{client.admin_email}</td>
                  <td className="table-col-1">
                    <div className="d-flex justify-content-end gap-4">
                      <AdminButton text="Edit client" underline={true} onClick={() => handleShowModal("edit", client)} />
                      <AdminButton text="Remove" showIcon={true} onClick={() => handleShowModal("remove", client)} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div ref={ref}></div>
        <AdminNotification show={alerts.updateSuccess} handleClose={() => handleCloseAlert("updateSuccess")} text="Client updated successfully." />
        <AdminNotification show={alerts.removeSuccess} handleClose={() => handleCloseAlert("removeSuccess")} text="Client has been removed." />
        <EditClientModal
          show={modals.edit}
          handleUpdateClient={handleUpdateClient}
          closeModal={() => handleCloseModal("edit")}
          clientData={currentClient}
          validateFormInputs={validateFormInputs}
          vailidateDomain={normalizeAndValidateDomain}
          checkExistingClient={checkExistingClient}
          handleShowError={handleShowError}
        />
        <ConfirmRemoveModal show={modals.remove} handleClose={() => handleCloseModal("remove")} itemType="client" item={currentClient} handleRemove={handleRemoveClient} />
        <NotificationModal show={modals.added} handleClose={() => handleCloseModal("added")} headingText="Client added" itemText={currentClient.name} bodyText="has been added as a client." />
        <NotificationModal show={modals.error} handleClose={() => handleCloseModal("error")} headingText="Error" bodyText={errorMessage} />
        <NotificationModal
          show={modals.associateRequest}
          handleClose={() => handleCloseModal("associateRequest")}
          headingText="Client request sent"
          itemText={currentClient.name}
          bodyText="has been notfied of your request to add them as a client."
        />
      </div>
    </>
  );
};

export default ClientsTab;
