import React from "react";

const AdminButton = ({ text, underline, showIcon, onClick }) => {
  return (
    <button className={`border-0 bg-transparent bw-text-primary fw-medium ${underline ? "bw-button-link text-decoration-underline" : ""}`} onClick={onClick}>
      {text}
      {showIcon && (
        <svg className="ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M6 18 17.94 6M18 18 6.06 6" />
        </svg>
      )}
    </button>
  );
};

export default AdminButton;
