// Pages/DashboardPage.js
import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import SummaryDashboard from "../Components/Dashboards/SummaryDashboard";
import DateRangePicker from "../Components/Shared/CustomDateRangePicker";
import { formatDateRange } from "../Utilities/Utils";
import PerformanceDashboard from "../Components/Dashboards/PerformanceDashboard";
import DropDownSelect from "../Components/Shared/DropDownSelect";
import SingleOptionSelect from "../Components/Shared/DropDownSingleSelect";
import { getCustomerList, getFiltersForCustomer } from "../API/dashboard";
import { useAuth } from "../Auth/AuthContext";
import { useData } from "../Data/GraphContext";
import Select from "react-select";

const DashboardTab = ({ currentCustomerId, setCurrentCustomerId }) => {
  const { authState } = useAuth();
  const { refreshData, resetData, hasGraphData } = useData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState("Summary");
  const [regions, setRegions] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [channels, setChannels] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCreatives, setSelectedCreatives] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [selectedDayparts, setSelectedDayparts] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectMenuOpen, setSelectMenuOpen] = useState(false);
  const [resetDropdowns, setResetDropdowns] = useState(false);

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startDate,
    endDate: new Date()
  });

  const [dateRangeButtonTitle, setDateRangeButtonTitle] = useState("Date Range");

  const handleSelectDashboard = (eventKey) => {
    setSelectedDashboard(eventKey);
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const token = authState.token;
        const response = await getCustomerList(token);
        setClients(response);

        if (response.length === 1) {
          handleSelectCustomer(response[0]);
        } else {
          const customerIdToRetrieve = selectedCustomer?.id || currentCustomerId;
          const clientToSelect = response.find((client) => client.id === customerIdToRetrieve);
          handleSelectCustomer(clientToSelect);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    // Updates the date range button title when a date is selected
    setDateRangeButtonTitle(formatDateRange(selectedDateRange.startDate, selectedDateRange.endDate));
    setShouldFetchData(true);
  }, [selectedDateRange]);

  const handleSelectedOptions = (optionType, selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option);

    switch (optionType) {
      case "Region":
        setSelectedRegions(selectedIds);
        break;
      case "Campaign":
        setSelectedCampaigns(selectedIds);
        break;
      case "Creative":
        setSelectedCreatives(selectedIds);
        break;
      case "Channel":
        setSelectedChannels(selectedIds);
        break;
      case "Weekday":
        setSelectedWeekdays(selectedIds);
        break;
      case "Daypart":
        setSelectedDayparts(selectedIds);
        break;
      case "Sites":
        setSelectedSites(selectedIds);
        break;
      default:
        console.warn("Unknown optionType: ", optionType);
    }

    setShouldFetchData(true);
  };

  const handleSelectCustomer = async (customer) => {
    setSelectedCustomer(customer);
    resetData();

    setSelectedRegions([]);
    setSelectedCampaigns([]);
    setSelectedCreatives([]);
    setSelectedChannels([]);
    setSelectedWeekdays([]);
    setSelectedDayparts([]);
    setSelectedSites([]);

    setResetDropdowns(true);

    setShouldFetchData(true);
    setCurrentCustomerId(customer?.id);

    setTimeout(() => {
      setResetDropdowns(false);
    }, 100);
  };

  const customerOptions = clients.map((client) => ({
    value: client.id,
    label: client.display_name
  }));

  useEffect(() => {
    if (!shouldFetchData || !selectedCustomer) return;

    const fetchData = async () => {
      const token = authState.token;
      const response = await getFiltersForCustomer(selectedCustomer.id, token);

      // Transform the data and include "All" as an option
      const transformData = (data) => {
        return [{ id: 0, display_name: "All" }, ...data];
      };

      setRegions(transformData(response.regions));
      setCreatives(transformData(response.creatives));
      setCampaigns(transformData(response.campaigns));
      setChannels(transformData(response.channels));
      setSites(transformData(response.sites));

      setShouldFetchData(false); // Added to ensure it doesn't fetch repeatedly
    };

    fetchData();
  }, [shouldFetchData, selectedCustomer, authState.token, currentCustomerId]); // Added authState.token as a dependency

  useEffect(() => {
    if (!shouldFetchData || !selectedCustomer) return;

    const refreshReport = async () => {
      setLoading(true);
      const clientId = selectedCustomer.id;

      try {
        await refreshData({
          clientId,
          selectedDateRange,
          selectedRegions,
          selectedCampaigns,
          selectedCreatives,
          selectedChannels,
          selectedWeekdays,
          selectedDayparts,
          selectedSites
        });
      } catch (error) {
        console.error("Error refreshing data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
        setShouldFetchData(false); // Ensure this is reset after fetching data
      }
    };

    refreshReport();
  }, [currentCustomerId, shouldFetchData, selectedCustomer, selectedDateRange, selectedRegions, selectedCampaigns, selectedCreatives, selectedChannels, selectedWeekdays, selectedDayparts, selectedSites, refreshData]); // Ensured all necessary dependencies are included

  const weekdays = [
    { id: 0, display_name: "All" },
    { id: 1, display_name: "Monday" },
    { id: 2, display_name: "Tuesday" },
    { id: 3, display_name: "Wednesday" },
    { id: 4, display_name: "Thursday" },
    { id: 5, display_name: "Friday" },
    { id: 6, display_name: "Saturday" },
    { id: 7, display_name: "Sunday" }
  ];

  const dayparts = [
    { id: 0, display_name: "All" },
    { id: 1, display_name: "Night" },
    { id: 2, display_name: "Breakfast" },
    { id: 3, display_name: "Morning" },
    { id: 4, display_name: "Lunch" },
    { id: 5, display_name: "Afternoon" },
    { id: 6, display_name: "Access" },
    { id: 7, display_name: "Peak" },
    { id: 8, display_name: "Off-Peak" }
  ];

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="dashboard-header bw-bg-secondary text-light mb-1 w-100 d-flex flex-wrap gap-3 sticky-top">
        <div className="d-flex align-items-center flex-wrap row-gap-3">
          {/** Datepicker */}
          <div className="d-flex justify-content-around align-items-center">
            <Dropdown className="d-inline me-3" drop="start" autoClose="outside" disabled={!selectedCustomer}>
              <Dropdown.Toggle id="datepicker-autoclose-true" className="px-3" variant="light" size="sm">
                {dateRangeButtonTitle || "Select Date Range"}
              </Dropdown.Toggle>
              <Dropdown.Menu className="m-0 p-0" style={{ zIndex: "10000" }}>
                <Dropdown.Item className="m-0 p-0" eventKey={"DatePicker"}>
                  <DateRangePicker setSelectedDateRange={setSelectedDateRange} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Select
            value={customerOptions.find((option) => option.value === selectedCustomer?.id) || null}
            onChange={(selectedOption) => handleSelectCustomer(clients.find((client) => client.id === selectedOption?.value))}
            options={customerOptions}
            placeholder="Select Client"
            // isClearable
            isDisabled={!clients.length}
            className={`dashboard-select text-black me-3 fs-6 ${selectMenuOpen ? "open" : ""} ${selectedCustomer ? "active" : ""}`}
            onMenuOpen={() => setSelectMenuOpen(true)}
            onMenuClose={() => setSelectMenuOpen(false)}
            onFocus={() => setSelectMenuOpen(true)}
            onBlur={() => setSelectMenuOpen(false)}
          />

          <DropDownSelect title={"Region"} options={regions} onApply={handleSelectedOptions}
                          disabled={!selectedCustomer} reset={resetDropdowns} />
          <DropDownSelect title={"Campaign"} options={campaigns} onApply={handleSelectedOptions}
                          disabled={!selectedCustomer} reset={resetDropdowns} />
          <DropDownSelect title={"Creative"} options={creatives} onApply={handleSelectedOptions}
                          disabled={!selectedCustomer} reset={resetDropdowns} />
          <DropDownSelect title={"Channel"} options={channels} onApply={handleSelectedOptions}
                          disabled={!selectedCustomer} reset={resetDropdowns} />
          <SingleOptionSelect title={"Sites"} options={sites} onApply={handleSelectedOptions}
                              disabled={!selectedCustomer} reset={resetDropdowns} />
          <DropDownSelect title={"Weekday"} options={weekdays} onApply={handleSelectedOptions}
                          disabled={!selectedCustomer} reset={resetDropdowns} />
          <DropDownSelect title={"Daypart"} options={dayparts} onApply={handleSelectedOptions}
                          disabled={!selectedCustomer} reset={resetDropdowns} />
          {/* <Button size={"sm"} variant={"outline-light"} onClick={refreshReport} disabled={!selectedCustomer}>
            <IoMdRefresh /> Refresh
          </Button> */}
        </div>
        <div className="fs-5 fw-bold">
          <Button variant={selectedDashboard === "Summary" ? "light" : "dark"} size="sm"
                  onClick={() => handleSelectDashboard("Summary")} className="me-3">
            Summary
          </Button>
          <Button variant={selectedDashboard === "Performance" ? "light" : "dark"} size="sm"
                  onClick={() => handleSelectDashboard("Performance")} className="me-3">
            Performance
          </Button>
        </div>
      </div>

      {loading ? (
        <div className="m-5">
          <i>Loading...</i>
        </div>
      ) : !hasGraphData && !selectedCustomer ? (
        <div className="m-5">
          <i>Select a client and refresh the data</i>
        </div>
      ) : !hasGraphData ? (
        <div>No data available</div>
      ) : (
        <div className="dashboard-content flex-grow-1 w-100 px-3">{selectedDashboard === "Summary" ?
          <SummaryDashboard /> : <PerformanceDashboard />}</div>
      )}
    </div>
  );
};

export default DashboardTab;
