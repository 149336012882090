// Components/TabContent/AgenciesTab.js
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import {
  associateAgency,
  createAgency,
  deleteAgency,
  getAssociatedAgencies,
  getUnassociatedAgencies
} from "../../API/admin";
import PrimaryButton from "../Buttons/PrimaryButton";
import AdminButton from "../Buttons/AdminButton";
import EditAgencyModal from "../Modals/EditAgencyModal";
import ConfirmRemoveModal from "../Modals/ConfirmRemoveModal";
import NotificationModal from "../Modals/NotificationModal";
import AdminNotification from "../Alerts/AdminNotification";
import { useAuth } from "../../Auth/AuthContext";
import useDebounce from "../../Hooks/useDebounce";

const AgenciesTab = () => {
  const [modals, setModals] = useState({
    edit: false,
    added: false,
    remove: false,
    error: false,
    associateRequest: false,
  });
  const [alerts, setAlerts] = useState({
    updateSuccess: false,
    removeSuccess: false,
  });
  const [inputValues, setInputValues] = useState({
    domain: "",
    name: "",
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: false,
    domain: false,
    name: false,
  });
  const [unassociatedAgencyData, setUnassociatedAgencyData] = useState([]);
  const [associatedAgencyData, setAssociatedAgencyData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [showCreateAgencyForm, setShowCreateAgencyForm] = useState(false);
  const suggestionsRef = useRef(null);
  const [currentAgency, setCurrentAgency] = useState({});
  const { authState } = useAuth();

  const ref = useRef();

  const handleShowModal = (modal, agency) => {
    setModals((prev) => ({
      ...prev,
      [modal]: true,
    }));
    setCurrentAgency(agency);
  };

  const handleCloseModal = (modal) => {
    setModals((prev) => ({
      ...prev,
      [modal]: false,
    }));
    setCurrentAgency({});
  };

  const handleCloseAlert = (alert) => {
    setAlerts((prev) => ({
      ...prev,
      [alert]: false,
    }));
  };

  const handleShowError = (message) => {
    setModals((prev) => ({
      ...prev,
      edit: false,
      error: true,
    }));
    setErrorMessage(message);
  };

  const handleScrollToBottom = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const checkExistingAgency = (domain) => {
    const allAgencies = associatedAgencyData.concat(unassociatedAgencyData);
    return allAgencies.some((agency) => normalizeAndValidateDomain(agency.domain).normalizedDomain === normalizeAndValidateDomain(domain).normalizedDomain);
  };

  const debouncedInputValue = useDebounce(inputValues.domain, 300);
  console.log("debouncedInputValue:", debouncedInputValue);

  const normalizeAndValidateDomain = (domain) => {
    domain = domain.toLowerCase();

    // Remove scheme (http, https) and 'www.'
    domain = domain.replace(/^https?:\/\/(www\.)?|^www\./, "");

    // Skip validation if the domain is too short to be valid
    if (domain.length < 3 || !domain.includes(".")) {
      return { valid: false, normalizedDomain: domain };
    }

    // Extract domain and path
    const urlParts = domain.split("/");
    const domainPart = urlParts.shift(); // Remove and store the first part (domain)
    const pathPart = urlParts.join("/"); // Join the remaining parts (path)

    // Regular expression to match valid domain names
    const domainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    // Validate the domain part using the regex
    if (!domainRegex.test(domainPart)) {
      return { valid: false, normalizedDomain: domain };
    }

    // Return the cleaned domain and path
    return { valid: true, normalizedDomain: domainPart + (pathPart ? "/" + pathPart : "") };
  };

  const validateFormInputs = (inputValues) => {
    let errors = {
      email: false,
      domain: false,
      name: false,
    };

    // Validate email
    if (!inputValues.email.includes("@") || inputValues.email.trim().length === 0) {
      errors.email = true;
    }

    // Validate domain
    const domainInput = normalizeAndValidateDomain(inputValues.domain);
    if (!domainInput.valid || inputValues.domain.trim().length === 0) {
      errors.domain = true;
    }

    // Validate name
    if (inputValues.name.trim().length === 0) {
      errors.name = true;
    }

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input change - ${name}:`, value); // Log the input change
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedAgency(suggestion);
    setInputValues((prev) => ({
      ...prev,
      domain: suggestion.domain,
    }));
    setSuggestions([]);
    setShowCreateAgencyForm(false);
  };

  const handleUpdateAgency = () => {
    handleCloseModal("edit");

    fetchAgencies();

    setTimeout(() => {
      setAlerts((prev) => ({
        ...prev,
        updateSuccess: true,
      }));
    }, 300);
  };

  const handleRemoveAgency = async (agencyId) => {
    try {
      // Implement deleteAgency functionality
      await deleteAgency(agencyId, authState.token);
      console.log("Agency deleted successfully");

      handleCloseModal("remove");
      await fetchAgencies();
      setTimeout(() => {
        setAlerts((prev) => ({
          ...prev,
          removeSuccess: true,
        }));
      }, 300);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error deleting agency:", error.message);
      }
    }
  };

  const handleCreateAndAssociateAgency = async () => {
    try {
      console.log("Creating and associating new agency...");
      const newAgency = await createAgency(
        {
          agency_name: inputValues.name,
          domain: inputValues.domain,
          admin_email: inputValues.email,
        },
        authState.token
      );
      console.log("New agency created and associated:", newAgency);

      setCurrentAgency((prev) => ({
        ...prev,
        name: inputValues.name,
      }));

      setInputValues({
        domain: "",
        name: "",
        email: "",
      });

      fetchAgencies();

      setTimeout(() => {
        setModals((prev) => ({
          ...prev,
          added: true,
        }));
      }, 200);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error creating and associating agency:", error.message);
      }
    }
  };

  const handleAssociateAgency = async (agency) => {
    try {
      console.log("Associating agency:", agency);
      await associateAgency(agency, authState.token);

      setCurrentAgency({ domain: agency.domain, name: agency.agency_name, email: agency.admin_email });

      setInputValues((prev) => ({
        ...prev,
        domain: "",
      }));

      setTimeout(() => {
        setModals((prev) => ({
          ...prev,
          added: true,
        }));
      }, 200);

      fetchAgencies();
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail.message;
        handleShowError(errorMessage);
      } else {
        console.log("Error associating agency:", error.message);
      }
    }
  };

  const fetchAgencies = useCallback(async () => {
    try {
      const associatedData = await getAssociatedAgencies(authState.token);
      const unassociatedData = await getUnassociatedAgencies(authState.token);
      setAssociatedAgencyData(associatedData);
      setUnassociatedAgencyData(unassociatedData);
    } catch (error) {
      console.error("Error fetching agencies: ", error);
    }
  }, [authState.token]);

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  useEffect(() => {
    if (!debouncedInputValue) {
      setSuggestions([]);
      setShowCreateAgencyForm(false);
      return;
    }

    // Always perform partial matching
    const normalizedInput = debouncedInputValue.toLowerCase().replace(/^https?:\/\/(www\.)?|^www\./, "");
    const filteredSuggestions = unassociatedAgencyData.filter((customer) => customer.domain.toLowerCase().includes(normalizedInput));

    if (!selectedAgency) {
      setSuggestions(filteredSuggestions);
    }

    // Full domain validation
    const { valid, normalizedDomain } = normalizeAndValidateDomain(debouncedInputValue);

    const matchedAgency = unassociatedAgencyData.find((customer) => normalizeAndValidateDomain(customer.domain).normalizedDomain === normalizedDomain);

    const associatedAgency = associatedAgencyData.find((customer) => normalizeAndValidateDomain(customer.domain).normalizedDomain === normalizedDomain);

    if (associatedAgency) {
      setSelectedAgency(associatedAgency);
      setShowCreateAgencyForm(false);
    } else if (matchedAgency) {
      setSelectedAgency(matchedAgency);
      setShowCreateAgencyForm(false);
    } else {
      setSelectedAgency(null);
      if (normalizeAndValidateDomain(debouncedInputValue).valid) {
        setShowCreateAgencyForm(true);
      } else setShowCreateAgencyForm(false);
    }
  }, [debouncedInputValue, unassociatedAgencyData, associatedAgencyData]);

  useEffect(() => {
    if (alerts.removeSuccess || alerts.updateSuccess) {
      handleScrollToBottom();
    }
  }, [alerts]);

  return (
    <>
      <div className="fs-4 w-100 fw-bolder bw-text-primary mt-4">Add Agency</div>
      <div style={{ maxWidth: "28rem" }}>
        <Form className="mt-3">
          <Form.Group className="form-group" controlId="searchAgency">
            <Form.Label className={`${formErrors.domain && "text-danger"}`}>Domain</Form.Label>
            <InputGroup>
              <InputGroup.Text>www.</InputGroup.Text>
              <Form.Control type="text" value={inputValues.domain} name="domain" onChange={handleInputChange} />
              {selectedAgency ? (
                associatedAgencyData.some((a) => a.id === selectedAgency.id) ? (
                  <PrimaryButton
                    text="Confirm"
                    onClick={() =>
                      setModals((prev) => ({
                        ...prev,
                        error: true,
                      }))
                    }
                  />
                ) : (
                  <PrimaryButton text="Confirm" onClick={() => handleAssociateAgency(selectedAgency)} />
                )
              ) : null}
            </InputGroup>

            {/* Suggestions List */}
            {suggestions.length > 0 && (
              <ul className="auto-complete pointer" ref={suggestionsRef} style={suggestionsListStyle}>
                {suggestions.map((suggestion) => (
                  <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)} style={suggestionItemStyle}>
                    {suggestion.domain}
                  </li>
                ))}
              </ul>
            )}

            {/* New Agency Creation Form */}
            {showCreateAgencyForm && (
              <>
                <Form.Group className="mt-4">
                  <Form.Label className={formErrors.name ? "text-danger" : ""}>Agency Name</Form.Label>
                  <Form.Control type="text" name="name" value={inputValues.name} onChange={handleInputChange} className={formErrors.name ? "border-danger" : ""} placeholder="Enter agency name" />
                  {formErrors.name && (
                    <div className="text-danger fs-7" style={{ position: "absolute" }}>
                      Please provide an agency name.
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label className={formErrors.email ? "text-danger" : ""}>Admin Email</Form.Label>
                  <Form.Control type="email" name="email" value={inputValues.email} onChange={handleInputChange} className={formErrors.email ? "border-danger" : ""} placeholder="Enter admin email" />
                  {formErrors.email && (
                    <div className="text-danger fs-7" style={{ position: "absolute" }}>
                      Invalid email address.
                    </div>
                  )}
                </Form.Group>
                <div className="d-flex justify-content-end mt-3">
                  <PrimaryButton text="Confirm" onClick={handleCreateAndAssociateAgency} />
                </div>
              </>
            )}
          </Form.Group>
        </Form>
      </div>

      {/* Associated Agencies Table */}
      <div className="mt-5">
        <div className="border-bottom pb-1 mb-2">
          <div className="fs-4 w-100 fw-bolder bw-text-primary mt-4">Agencies</div>
        </div>
        <Table responsive>
          <tbody className="admin-table">
            {associatedAgencyData &&
              associatedAgencyData.map((agency) => (
                <tr key={agency.id}>
                  <td className="table-col-1">
                    <div className="d-flex align-items-center gap-3">{agency.agency_name}</div>
                  </td>
                  <td className="table-col-1">{agency.domain}</td>
                  <td className="table-col-1">{agency.admin_email}</td>
                  <td className="table-col-1">
                    <div className="d-flex justify-content-end gap-4">
                      <AdminButton text="Edit Agency" underline={true} onClick={() => handleShowModal("edit", agency)} />
                      <AdminButton text="Remove" showIcon={true} onClick={() => handleShowModal("remove", agency)} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {/* Notifications and Modals */}
        <div ref={ref}></div>
        <AdminNotification show={alerts.updateSuccess} handleClose={() => handleCloseAlert("updateSuccess")} text="Agency updated successfully." />
        <AdminNotification show={alerts.removeSuccess} handleClose={() => handleCloseAlert("removeSuccess")} text="Agency has been removed." />
        <EditAgencyModal
          show={modals.edit}
          handleUpdateAgency={handleUpdateAgency}
          closeModal={() => handleCloseModal("edit")}
          agencyData={currentAgency}
          validateFormInputs={validateFormInputs}
          validateDomain={normalizeAndValidateDomain}
          checkExistingAgency={checkExistingAgency}
          handleShowError={handleShowError}
        />
        <ConfirmRemoveModal show={modals.remove} handleClose={() => handleCloseModal("remove")} itemType="agency" item={currentAgency} handleRemove={handleRemoveAgency} />
        <NotificationModal show={modals.added} handleClose={() => handleCloseModal("added")} headingText="Agency Added" itemText={currentAgency.name} bodyText="has been added as an agency." />
        <NotificationModal show={modals.error} handleClose={() => handleCloseModal("error")} headingText="Error" itemText={inputValues.domain} bodyText={errorMessage} />
        <NotificationModal
          show={modals.associateRequest}
          handleClose={() => handleCloseModal("associateRequest")}
          headingText="Agency Request Sent"
          itemText={currentAgency.name}
          bodyText="has been notified of your request to add them as an agency."
        />
      </div>
    </>
  );
};

// Optional: Define inline styles for better UX
const suggestionsListStyle = {
  listStyleType: "none",
  padding: "0",
  margin: "0",
  border: "1px solid #ccc",
  borderTop: "none",
  maxHeight: "150px",
  overflowY: "auto",
  position: "absolute",
  width: "100%",
  backgroundColor: "white",
  zIndex: 1000
};

const suggestionItemStyle = {
  padding: "8px",
  cursor: "pointer",
  borderBottom: "1px solid #eee"
};

export default AgenciesTab;