// Components/TabContent/CampaignsTab.js
import React from "react";
import FilteredTable from "../Shared/FilteredTable";  // Import the generic table component
import TableOptions from "../TableOptions";

function CampaignsTab({ data, showEditCampaign }) {
  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "campaign_name", // Corresponds to campaign.campaign_name
      },
      {
        Header: "Start Date",
        accessor: "start_date", // Corresponds to campaign.start_date
        // Enable sorting for this column
        Cell: ({ value }) => {
          if (!value) return "N/A";
    const [year, month, day] = value.split("-");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${day}-${monthNames[parseInt(month, 10) - 1]}-${year}`;
        },
      },
      {
        Header: "End Date",
        accessor: "end_date", // Corresponds to campaign.end_date
        // Enable sorting for this column
        Cell: ({ value }) => {
          if (!value) return "N/A";
    const [year, month, day] = value.split("-");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${day}-${monthNames[parseInt(month, 10) - 1]}-${year}`;
        },
      },
      {
        Header: "Options",
        accessor: "options",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <TableOptions
            copy={false}
            edit
            onEdit={() => showEditCampaign(row.original)} // Pass campaign data to the edit handler
          />
        ),
      },
    ],
    [showEditCampaign]
  );

  return (
    <div>
      <FilteredTable columns={columns} data={data} /> {/* Use the FilteredTable component */}
    </div>
  );
}

export default CampaignsTab;
