// API/auth.js
import axiosInstance from "./base";
import { handleRequest } from "./utils";

export const resetPassword = (email) => {
  return handleRequest("post", "/password-reset", { email });
};

export const verifyResetToken = (token) => {
  return axiosInstance.get("/verify-reset-token", { params: { token } });
};

export const resetNewPassword = ({ token, newPassword }) => {
  return axiosInstance.post("/reset-password", { token, new_password: newPassword });
};

export const acceptInvitation = (token) => {
  return handleRequest("post", "/accept-invitation", { token }, "Invitation accepted successfully.");
};
