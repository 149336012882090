import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmRemoveModal = ({ show, handleClose, handleRemove, itemType, item }) => {
  const [currentItem, setCurrentItem] = useState({});

  const handleRemoveItem = () => {
    if (currentItem) {
      handleRemove(currentItem.id);
    }
  };

  useEffect(() => {
    if (item) {
      setCurrentItem(item);
    }
  }, [item]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title className="bw-text-primary fw-bold">Remove {itemType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 mb-5">
          <p>
            {`Are you sure you want to remove ${itemType} `}
            <span
              className="d-inline-block fw-semibold bw-text-primary">{currentItem.cust_name ? currentItem.cust_name : currentItem.email ? currentItem.email : currentItem.agency_name ? currentItem.agency_name : ""}</span>?
          </p>
        </div>
        <div className="pt-3 d-flex justify-content-end">
          <Button
            variant="danger"
            className="w-25 me-2"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className="w-25"
            onClick={handleRemoveItem}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmRemoveModal;
