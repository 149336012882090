// API/base.js
import axios from "axios";
import { notifyError } from "../Utilities/ToastService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/api"; // Base URL for the API

// Set up a common instance of axios to reuse across the module.
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { "Content-Type": "application/json" }
});

// Token refresh logic variables
let isRefreshing = false;
let refreshSubscribers = [];

// Function to add requests to a queue waiting for the token to refresh
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// Function to notify all requests waiting for the new token
const onRefreshed = (newAccessToken) => {
  refreshSubscribers.forEach(callback => callback(newAccessToken));
  refreshSubscribers = [];
};

// Axios interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = localStorage.getItem("refreshToken");
          const response = await axios.post(`${API_BASE_URL}/refresh-token`, { token: refreshToken });

          const newAccessToken = response.data.new_token;
          localStorage.setItem("accessToken", newAccessToken);
          isRefreshing = false;
          onRefreshed(newAccessToken); // Notify all the waiting requests with the new token

          // Retry the original request with the new access token
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login"; // Log the user out if refresh fails
          isRefreshing = false;
          throw refreshError;
        }
      }

      // Queue the request and retry it once the token refresh is done
      return new Promise((resolve) => {
        subscribeTokenRefresh((newAccessToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
