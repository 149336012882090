// Components/Modals/BookingModal.js
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";
import BookingTypeButtonGroup from "../Buttons/BookingTypeButtonGroup";
import { saveNewBooking, updateBooking } from "../../API/managetags";
import CreativeTypeButtonGroup from "../Buttons/CreativeTypeButtonGroup";

const BookingModal = ({
                        show,

                        handleClose,
                        customer_id,
                        token,
                        refreshData,
                        bookingData,
                        channels,
                        campaigns
                      }) => {
  const createInitialBookingState = useCallback(() => ({
    customer_id: customer_id,
    booking_name: "",
    channel_id: null,
    campaign_id: null,
    booking_type: 1,
    start_date: new Date(),
    end_date: new Date(),
    cpm: 0
  }), [customer_id]);

  const [booking, setBooking] = useState(createInitialBookingState);

  const resetForm = () => {
    setBooking(createInitialBookingState());
  };
  console.log("bookingData", bookingData);
  useEffect(() => {
    if (show && !bookingData) {
      resetForm();
    } else if (bookingData) {
      setBooking(bookingData);
    }
  }, [show, bookingData, createInitialBookingState]);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormChange = (name, value) => {
    setBooking((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleTypeChange = (type) => {
    setBooking((prevState) => ({
      ...prevState,
      booking_type: type
    }));
  };

  useEffect(() => {
    if (show && channels.length === 1 && bookingData?.campaigns?.length === 1 && !bookingData) {
      setBooking((prevState) => ({
        ...prevState,
        channel_id: channels[0].id,
        campaign_id: bookingData.campaigns[0].id
      }));
    }
  }, [show, channels, bookingData]);

  const formatToCustomDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      console.error("Invalid date passed to formatToCustomDate:", inputDate);
      return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleCreateBooking = async (e) => {
    e.preventDefault();

    const modifiedBooking = {
      ...booking,
      start_date: formatToCustomDate(booking.start_date),
      end_date: formatToCustomDate(booking.end_date),
      cpm: parseFloat(booking.cpm),
      channel_id: booking.channel_id?.value || booking.channel_id,
      campaign_id: booking.campaign_id?.value || booking.campaign_id
    };

    if (bookingData) {
      await updateBooking(modifiedBooking, token);
    } else {
      await saveNewBooking(modifiedBooking, token);
    }

    refreshData();
    handleClose();
  };

  useEffect(() => {
    setBooking((prevState) => ({
      ...prevState,
      customer_id: customer_id
    }));
  }, [customer_id]);

  useEffect(() => {
    const validateForm = () => {
      const isValid = booking?.booking_name.trim().length > 3 && parseFloat(booking.cpm) >= 0 && booking?.channel_id !== null && booking.campaign_id !== null;
      setIsFormValid(isValid);
    };
    validateForm();
  }, [booking]);

  const channelOptions = channels.map(channel => ({
    value: channel.id,
    label: channel.channel_name
  }));

  // Add a check to ensure bookingData and campaigns are not null/undefined
  const campaignOptions = campaigns.map(campaign => ({
    value: campaign.id,
    label: campaign.campaign_name
  }));

  const selectedChannel = channelOptions.find(option => option.value === booking.channel_id);
  const selectedCampaign = campaignOptions.find(option => option.value === booking.campaign_id);

  return (
    <Modal size="lg" show={show} onHide={handleClose} onExited={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-4 w-100 fw-bolder bw-text-primary">{bookingData ? "Update Booking" : "New Booking"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row className="my-3">
          <Col>
            <Form onSubmit={handleCreateBooking}>
              <Row className="mb-3">
                <Col>
                  <BookingTypeButtonGroup selectedType={booking.booking_type} onTypeChange={handleTypeChange} />
                </Col>
              </Row>
              <Form.Group controlId="campaign">
                <div className="tiny-heading">Campaign</div>
                <Select value={selectedCampaign}
                        onChange={(selectedOption) => handleFormChange("campaign_id", selectedOption)}
                        options={campaignOptions} placeholder="Select Campaign" classNamePrefix="react-select" />
              </Form.Group>
              <Form.Group controlId="bookingName" className="mt-3">
                <div className="tiny-heading">Booking Name</div>
                <Form.Control type="text" value={booking.booking_name} placeholder="Booking Name"
                              onChange={(e) => handleFormChange("booking_name", e.target.value)} maxLength={100} />
                <div
                  className={`${booking.booking_name.length < 90 ? "bw-text-primary" : "bw-text-danger"} fs-8 pt-1 text-end fw-bold`}>{100 - booking.booking_name.length}/100
                </div>
              </Form.Group>
              <Form.Group controlId="channel">
                <div className="tiny-heading">Channel</div>
                <Select value={selectedChannel}
                        onChange={(selectedOption) => handleFormChange("channel_id", selectedOption)}
                        options={channelOptions} placeholder="Select Channel" classNamePrefix="react-select" />
              </Form.Group>
              <Row className="my-3">
                <Col className="col-4 d-flex flex-column">
                  <div className="tiny-heading">Start</div>
                  <DatePicker
                    selected={booking.start_date}
                    onChange={(date) => handleFormChange("start_date", date)}
                    placeholder="Start"
                    selectsStart
                    startDate={booking.start_date}
                    endDate={booking.end_date}
                    dateFormat="dd/MM/yyyy"
                    className="custom-datepicker w-100"
                  />
                </Col>
                <Col className="col-4 d-flex flex-column">
                  <div className="tiny-heading">End</div>
                  <DatePicker
                    selected={booking.end_date}
                    onChange={(date) => handleFormChange("end_date", date)}
                    selectsEnd
                    startDate={booking.start_date}
                    endDate={booking.end_date}
                    minDate={booking.start_date}
                    dateFormat="dd/MM/yyyy"
                    className="custom-datepicker w-100"
                  />
                </Col>
                <Col className="col-4">
                  <div className="tiny-heading">CPM</div>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <NumericFormat thousandSeparator={true} className="form-control" required value={booking.cpm}
                                   placeholder="CPM" onChange={(e) => handleFormChange("cpm", e.target.value)} />
                  </InputGroup>
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-end mt-4">
                  <Button className="me-2 bw-button-secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" className="w-25 me-2 bw-button-primary bw-button" disabled={!isFormValid}>
                    {bookingData ? "Update Booking" : "Create Booking"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BookingModal;
