// Components/Charts/ResponseByRegion.js
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { numberWithCommas } from "../../Utilities/Utils";
import { useData } from "../../Data/GraphContext";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import "ol/ol.css";
import AllResponsesByRegionModal from "../Modals/AllResponsesByRegionModal";
import HelpTooltip from "../Shared/HelpToolTip";

const ResponseByRegion = () => {
  const { responsesByRegion, responsesBySite } = useData();
  const mapRef = useRef();
  const [mapInstance, setMapInstance] = useState(null);
  const [vectorLayer, setVectorLayer] = useState(null);

  const getRadius = (responses) => {
    return Math.sqrt(responses) / 2;
  };

  const regionData = useMemo(() => {
    return (responsesByRegion?.table_data || []).sort((a, b) => b.Responses - a.Responses);
  }, [responsesByRegion]);

  const siteData = responsesBySite?.table_data || [];

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    if (!mapRef.current) return;

    // Initialize the map with a fallback zoom and center
    const fallbackZoom = parseFloat(responsesByRegion?.summary_data?.zoom) || 5;
    const fallbackCenter = fromLonLat([
      parseFloat(responsesByRegion?.summary_data?.centre_long) || 174.886,
      parseFloat(responsesByRegion?.summary_data?.centre_lat) || -40.9006
    ]);

    const initialVectorLayer = new VectorLayer({
      source: new VectorSource()
    });

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
            source: new XYZ({
              url: "https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            })
          }
        ),
        initialVectorLayer

      ],
      view: new View({
        center: fallbackCenter,
        zoom: fallbackZoom,
        maxZoom: 10
      })
    });

    setMapInstance(map);
    setVectorLayer(initialVectorLayer);

    // console.log("Map initialized with fallback center and zoom.");

    return () => map.setTarget(null); // Cleanup on unmount
  }, [responsesByRegion]);

  // Effect to update markers and fit the map to the data extent
  useEffect(() => {
    if (!mapInstance || !vectorLayer) return;

    const features = regionData.map((location) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([location.Longitude, location.Latitude])),
        name: `${location.Region}: ${numberWithCommas(location.Responses)} responses`
      });

      feature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: location.rad_size || getRadius(location.Responses),
            fill: new Fill({ color: "#00a2e8" }),
            stroke: new Stroke({ color: "#00a2e8", width: 1 })
          })
        })
      );

      return feature;
    });

    // Clear old features and add new ones
    const vectorSource = vectorLayer.getSource();
    vectorSource.clear();
    vectorSource.addFeatures(features);

    // console.log("Features added:", features.map((f) => f.getGeometry().getCoordinates()));

    if (features.length > 0) {
      // Fit the map to the extent of the new features
      const extent = vectorSource.getExtent();
      // console.log("Calculated extent:", extent);
      mapInstance.getView().fit(extent, {
        padding: [50, 50, 50, 50],
        duration: 1000
      });
    } else {
      // No data, fallback to the provided lat/lon and zoom from summary_data
      const centreLat = responsesByRegion?.summary_data?.centre_lat || -40.9006;
      const centreLong = responsesByRegion?.summary_data?.centre_long || 174.886;
      const fallbackZoom = parseFloat(responsesByRegion?.summary_data?.zoom) || 5;
      // console.log("No data. Default center:", centreLat, centreLong, "Zoom:", fallbackZoom);
      mapInstance.getView().setCenter(fromLonLat([centreLong, centreLat]));
      mapInstance.getView().setZoom(fallbackZoom);
    }
  }, [mapInstance, vectorLayer, regionData, responsesByRegion]);

  return (
    <Card className="h-100">
      <Card.Body className="d-flex flex-column justify-content-between">
        <Row className="m-0 h-100">
          <Col xs={12} lg={6} className="pb-3">
            <div ref={mapRef} style={{ height: "100%", width: "100%", minHeight: "300px" }}></div>
          </Col>

          <Col xs={12} lg={6}>
            <Table>
              <thead>
                <tr>
                  <th className="bw-bg-primary text-light">Region</th>
                  <th className="bw-bg-primary text-light text-center">Total Visits</th>
                  <th className="bw-bg-primary text-light text-center">Visit %</th>
                </tr>
              </thead>
              <tbody>
                {regionData.slice(0, 5).map((region) => (
                  <tr key={region.Region}>
                    <td>{region?.Region}</td>
                    <td className="text-center">{numberWithCommas(region?.Responses)}</td>
                    <td className="text-center">{numberWithCommas(parseFloat(region?.Percentage) * 100, 1)}%</td>
                  </tr>
                ))}
                {regionData.length > 5 && (
                  <tr>
                    <td colSpan="3" className="text-center">
                      <Button variant="link" onClick={handleShow}>
                        Show all {regionData.length} regions
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="flex-grow-1 align-items-end m-0">
          <Col className="col-12">
            <Table className="m-0">
              <thead>
                <tr>
                  <th className="bw-bg-primary">Page</th>
                  <th className=" bw-bg-primary text-center">Total Visits</th>
                  <th className=" bw-bg-primary text-center">Response Rate</th>
                  <th className=" bw-bg-primary text-center">CPVisit</th>
                  <th className="bw-bg-primary" style={{ position: "relative" }}>
                    <HelpTooltip helpText={"All unique visitors to the website/app or pages specified by the Page description. Specified pages are a subset of All of Site visits."} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {siteData.map((site) => (
                  <tr key={site.Site}>
                    <td>{site.Site}</td>
                    <td className="text-center">{numberWithCommas(site.Responses)}</td>
                    <td className="text-center">{(site.Response_Rate_Percent * 100).toFixed(1)}%</td>
                    <td className="text-center">${parseFloat(site.CPR).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <AllResponsesByRegionModal showModal={showModal} handleClose={handleClose} regionData={regionData} />
      </Card.Body>
    </Card>
  );
};

export default ResponseByRegion;
