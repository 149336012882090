// Components/Shared/MetricBox.js
import React from 'react';
import {Card} from "react-bootstrap";
import HelpTooltip from './HelpToolTip';
import {numberWithCommas} from "../../Utilities/Utils";

/**
 *
 * @param {string} title Card title e.g. Actual Impressions
 * @param {number} value Value to display
 * @param {boolean} dollar Determines whether to show a dollar sign
 * @param {boolean} percent Determines whether to show a percent sign
 * @param {string} variant CSS class variant
 * @param {string} helpTextHtml HTML for help tooltip
 * @param {string} placement Tooltip placement
 * @param {string} color Icon color
 * @returns Card
 */
const MetricBox = ({
                       title,
                       value,
                       dollar = false,
                       percent = false,
                       variant = "primary",
                       helpTextHtml,
                       placement,
                       color
                   }) => {
    return (
      <>
        {/* <Card className="overflow-hidden w-100 h-100 rounded-0">
            {helpTextHtml && <HelpTooltip helpTextHtml={helpTextHtml} placement={placement} color={color}/>}
            <Card.Body
                className={`d-flex flex-column justify-content-center align-items-center rounded-0 h-100 ${variant === "primary" ? "bw-bg-primary text-light" : variant === "secondary" ? "bw-bg-secondary text-light" : "bg-light text-tint"}`}>
                <div className="fw-bold text-center">{title}</div>
                <div className="fs-4 fw-bold text-center">
                    {dollar ? "$" : ""}
                    {percent ? numberWithCommas(value, 1) : dollar ? numberWithCommas(value, 2) : numberWithCommas(value)}
                    {percent ? "%" : ""}
                </div>
            </Card.Body>
        </Card> */}
        <div className="d-flex flex-column justify-content-center align-items-center bw-bg-primary h-100 p-3">
          <div className="fw-bold text-center">{title}</div>
          <div className="fs-4 fw-bold text-center">
            {dollar ? "$" : ""}
            {percent ? numberWithCommas(value, 1) : dollar ? numberWithCommas(value, 2) : numberWithCommas(value)}
            {percent ? "%" : ""}
          </div>
        </div>
      </>
    );
};

export default MetricBox;
