// CampaignModal.js
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { NumericFormat } from "react-number-format";
import { saveNewCampaign, updateCampaign } from "../../API/managetags";

const CampaignModal = ({
                         show,
                         handleClose,
                         customer_id,
                         token,
                         refreshData,
                         campaignData,
                         channels
                       }) => {
  const createInitialCampaignState = useCallback(() => ({
    customer_id: customer_id,
    campaign_name: "",
    start_date: new Date(),
    end_date: new Date()
  }), [customer_id]);

  const [campaign, setCampaign] = useState(createInitialCampaignState);

  const resetForm = () => {
    setCampaign(createInitialCampaignState());
  };

  useEffect(() => {
    if (show && !campaignData) {
      resetForm();
    } else if (campaignData) {
      setCampaign(campaignData);
    }
  }, [show, campaignData, createInitialCampaignState]);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormChange = (name, value) => {
    setCampaign((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (show && channels.length === 1 && !campaignData) {
      setCampaign((prevState) => ({
        ...prevState
      }));
    }
  }, [show, channels, campaignData]);

  const formatToCustomDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      console.error("Invalid date passed to formatToCustomDate:", inputDate);
      return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleCreateCampaign = async (e) => {
    e.preventDefault();

    const modifiedCampaign = {
      ...campaign,
      start_date: formatToCustomDate(campaign.start_date),
      end_date: formatToCustomDate(campaign.end_date)
    };

    if (campaignData) {
      await updateCampaign(modifiedCampaign, token);
    } else {
      await saveNewCampaign(modifiedCampaign, token);
    }

    refreshData();
    handleClose();
  };

  useEffect(() => {
    setCampaign((prevState) => ({
      ...prevState,
      customer_id: customer_id
    }));
  }, [customer_id]);

  useEffect(() => {
    const validateForm = () => {
      const isValid = campaign?.campaign_name.trim().length > 3;
      setIsFormValid(isValid);
    };
    validateForm();
  }, [campaign]);


  return (
    <Modal size="lg" show={show} onHide={handleClose} onExited={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-4 w-100 fw-bolder bw-text-primary">{campaignData ? "Update Campaign" : "New Campaign"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row className="my-3">
          <Col>
            <Form onSubmit={handleCreateCampaign}>
              <Form.Group controlId="campaignName">
                <div className="tiny-heading">Campaign Name</div>
                <Form.Control type="text" value={campaign.campaign_name} placeholder="Campaign Name" onChange={(e) => handleFormChange("campaign_name", e.target.value)} maxLength={100} />
                <div className={`${campaign.campaign_name.length < 90 ? "bw-text-primary" : "bw-text-danger"} fs-8 pt-1 text-end fw-bold`}>{100 - campaign.campaign_name.length}/100</div>
              </Form.Group>
              <Row className="my-3">
                <Col className="col-4 d-flex flex-column">
                  <div className="tiny-heading">Start</div>
                  <DatePicker
                    selected={campaign.start_date}
                    onChange={(date) => handleFormChange("start_date", date)}
                    placeholder="Start"
                    selectsStart
                    startDate={campaign.start_date}
                    endDate={campaign.end_date}
                    dateFormat="dd/MM/yyyy"
                    className="custom-datepicker w-100"
                  />
                </Col>
                <Col className="col-4 d-flex flex-column">
                  <div className="tiny-heading">End</div>
                  <DatePicker
                    selected={campaign.end_date}
                    onChange={(date) => handleFormChange("end_date", date)}
                    selectsEnd
                    startDate={campaign.start_date}
                    endDate={campaign.end_date}
                    minDate={campaign.start_date}
                    dateFormat="dd/MM/yyyy"
                    className="custom-datepicker w-100"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end mt-4">
                  <Button className="me-2 bw-button-secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" className="w-25 me-2 bw-button bw-button-primary" disabled={!isFormValid}>
                    {campaignData ? "Update Campaign" : "Create Campaign"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignModal;
