import React from "react";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";

function CopyToClipboardButton({ textToCopy }) {

  const triggerCopy = () => {
    //copyToClipboard(textToCopy);

    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.success("Copied to clipboard!", {
        autoClose:1500,
      });
    });
  };

  return (
    <>
      <MdContentCopy
        className="pointer bw-text-tint me-5"
        size={18}
        onClick={triggerCopy}
      />
    </>
  );
}

export default CopyToClipboardButton;
