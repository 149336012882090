// Components/Charts/Correlation_tt.js
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title
} from "chart.js";
import { useData } from "../../Data/GraphContext";
import GaugeChart from "./GaugeChart";
import HelpTooltip from "../Shared/HelpToolTip";

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Legend, LineController);

const Correlation = React.memo(({ type }) => {
  const { responsesOverTime, impressionsBeforeVisit } = useData();

  const correlation = responsesOverTime?.summary_data?.correlation || 0;
  const pareto = impressionsBeforeVisit?.summary_data?.Views_for_80_Percent || 0;

  return (
    <Card className="d-flex flex-grow-1 h-100 bw-bg-primary no-border-card">
      {type === "correlation" ? (
        <Card.Header className="bw-bg-primary text-light">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div style={{ fontWeight: "bold" }}>Correlation</div>
              <div>Impressions / All Visits</div>
            </div>
            <HelpTooltip helpText="Correlation between impressions and visits" />
          </div>
        </Card.Header>
      ) : (
        <Card.Header className="bw-bg-primary text-light no-border-card">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div style={{ fontWeight: "bold" }}>Pareto - 80% Rule</div>
              <div>Number of Impressions to Deliver 80% Visits</div>
            </div>
            <HelpTooltip helpText="How many impressions to serve to each IP customer before 80% of visits occur" />
          </div>
        </Card.Header>
      )}
      <Card.Body>
        <Row className="h-100 d-flex flex-column bw-bg-primary">
          {type === "correlation" ? (
            <Col className="flex-grow-1 d-flex flex-column text-center">
              <div className="d-flex flex-grow-1 justify-content-center align-items-center bw-bg-primary">
                <GaugeChart value={correlation} chartType={type} />
              </div>
            </Col>
          ) : (
            <Col className="flex-grow-1 d-flex flex-column text-center">
              <div className="d-flex flex-grow-1 justify-content-center align-items-center bw-bg-primary">
                <GaugeChart value={pareto} chartType={type} />
              </div>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
});

export default Correlation;
