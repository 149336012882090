// Components/PerformanceProgress_tt.js
import React from 'react';
import {Col, Row, Card} from 'react-bootstrap';
import MetricBox from '../Shared/MetricBox';
import {useData} from "../../Data/GraphContext";


const PerformanceProgress = () => {
    const {impressionSummary} = useData();

    const additionalHelpTextHtml = `
    <p><strong>Ad Spend</strong> is the Total Advertising Spend in the selected date range calculated by number of impressions served by the CPM.</p>
    <p><strong>Actual Impressions</strong> is the total number of Impressions delivered in the selected date range.</p>
    <p><strong>Total Visits</strong> is the Unique Responses to a Website or App.</p>
    <p><strong>Response Rate %</strong> is the Visits / Impressions percentage for the selected date range.</p>
    <p><strong>CPM Actual</strong> is the Cost per Thousand for the period or Campaign selected.</p>
    <p><strong>CPV</strong> is the Cost per Visit based on total Ad Spend / number of Visits.</p>
  `;

    const metrics = [
      {
        title: "Ad Spend",
        value: impressionSummary?.summary_data?.Ad_Spend || 0,
        type: "dollar",
        variant: "primary",
        helpTextHtml: additionalHelpTextHtml,
        placement: "bottom", // Specify custom placement
        color: "white", // Specify custom color
        width: "full",
      },
      {
        title: "Actual Impressions",
        value: `${impressionSummary?.summary_data?.Actual_Impressions}` || 0,
        type: "number",
        variant: "primary",
        grow: true,
        width: "half",
      },
      {
        title: "Total Visits",
        value: `${impressionSummary?.summary_data?.Total_Responses}` || 0,
        type: "number",
        variant: "primary",
        width: "half",
      },
      {
        title: "CPM Actual",
        value: `${impressionSummary?.summary_data?.CPM_Actual}` || 0,
        type: "dollar",
        variant: "primary",
        width: "half",
      },
      {
        title: "Response Rate",
        value: `${impressionSummary?.summary_data?.Response_Rate * 100}` || 0,
        type: "percent",
        variant: "primary",
        width: "half",
      },
      {
        title: "Cost Per Visit",
        value: `${impressionSummary?.summary_data?.Current_CPR}` || 0,
        type: "dollar",
        variant: "primary",
        width: "full",
      },
    ];
    const colConfig = {
      "Ad Spend": { xs: 12, md: 12 },
      "Actual Impressions": { xs: 12, md: 6 },
      "Total Visits": { xs: 12, md: 6 },
      "CPM Actual": { xs: 12, md: 6 },
      "Response Rate": { xs: 12, md: 6 },
      CPV: { xs: 12, md: 12 },
    };

    const getColSize = (title, size) => colConfig[title]?.[size] || 12;

    return (
      <>
        {/* <Card className="h-100">
        <Card.Body className="d-flex justify-content-center align-items-top p-0">
          <Row className="m-0 w-100">
            {metrics.map((metric, index) => (
              <Col key={index} xs={getColSize(metric.title, "xs")} md={getColSize(metric.title, "md")} className="p-0 d-flex">
                <div className="flex-grow-1 d-flex">
                  <MetricBox
                    title={metric.title}
                    value={metric.value}
                    dollar={metric.type === "dollar"}
                    percent={metric.type === "percent"}
                    variant={metric.variant}
                    helpTextHtml={metric.title === "Ad Spend" ? metric.helpTextHtml : null}
                    placement={metric.placement}
                    color={metric.color}
                    className="flex-grow-1"
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card> */}
        <div className="d-flex gap-2 flex-wrap border h-100">
          {metrics.map((metric, index) => (
            <div key={index} className={`${metric.width === "full" ? "w-100" : "chart-stat-50"}`}>
              <MetricBox
                title={metric.title}
                value={metric.value}
                dollar={metric.type === "dollar"}
                percent={metric.type === "percent"}
                variant={metric.variant}
                helpTextHtml={metric.title === "Ad Spend" ? metric.helpTextHtml : null}
                placement={metric.placement}
                color={metric.color}
                className="flex-grow-1"
              />
            </div>
          ))}
        </div>
      </>
    );
};

export default PerformanceProgress;
