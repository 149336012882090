export const copyToClipboard = (textToCopy) => {
  // Using the Clipboard API to write text
  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      // Success feedback

    })
    .catch(err => {
      // Error handling

    });
};