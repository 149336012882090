// Components/LoginForm.js
import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginButton from "./Buttons/LoginButton";
import { useAuth } from "../Auth/AuthContext";
import TermsAndConditionsModal from "./Modals/TermsAndConditionsModal";
import ResetPasswordModal from "./Modals/ResetPasswordModal"; // Import the new modal

const LoginForm = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false); // State for reset password modal

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleOpenResetModal = () => setResetModalOpen(true);
  const handleCloseResetModal = () => setResetModalOpen(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError("");
    setLoading(true);

    try {
      const result = await login(email, password);
      if (result.status === "success") {
        navigate("/home");
      } else {
        setLoginError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Unexpected error during login:", error);
      setLoginError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container className="m-5 w-50">
        <Form onSubmit={handleLogin}>
          <Form.Control
            type="email"
            placeholder="EMAIL"
            onChange={(e) => setEmail(e.target.value)}
            className="mb-1 rounded-0"
          />
          <Form.Control
            type="password"
            placeholder="PASSWORD"
            onChange={(e) => setPassword(e.target.value)}
            className="mb-1 rounded-0"
          />
          <div className="w-100 d-flex justify-content-end mt-1">
            <LoginButton loading={loading} />
          </div>
          <div className="login-error justify-content-end mt-2 fs-10">{loginError && loginError}</div>
          {/* Reset Password Link */}
          <div className="w-100 d-flex justify-content-end mt-2">
            <span
              onClick={handleOpenResetModal}
              className="text-light fs-10"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Click here to reset your password
            </span>
          </div>

          <div className="fs-8 text-light terms-container">
            By signing in I agree to the{" "}
            <a
              href="#"
              style={{ color: "text-light", cursor: "pointer", textDecoration: "underline" }}
              onClick={(e) => {
                e.preventDefault();
                handleOpenModal();
              }}
            >
              Platform License Agreement
            </a>
          </div>
          <div className="w-100 d-flex justify-content-end text-light fs-8 pt-5">
            © Copyright of Mime Analytics NZ Limited
          </div>

        </Form>
      </Container>
      <TermsAndConditionsModal open={modalOpen} handleClose={handleCloseModal} />
      <ResetPasswordModal show={resetModalOpen} handleClose={handleCloseResetModal} />
    </>
  );
};

export default LoginForm;
