// API/utils.js
import axiosInstance from "./base";
import { notifyError, notifySuccess } from "../Utilities/ToastService";

export const handleRequest = async (method, url, data = {}, successMessage = null, showError = true, showSuccess = true) => {
  try {
    const response = await axiosInstance({
      method,
      url,
      data
    });

    if (successMessage && showSuccess) {
      notifySuccess(successMessage);
    }

    return response.data;
  } catch (error) {
    console.error(`Error with ${method.toUpperCase()} request to ${url}:`, error);

    if (showError) {
      const errorMessage = error.response?.data?.message || error.response?.data?.detail || "An error occurred";
      notifyError(`Error: ${error.response?.status} - ${errorMessage}`);
    } else {
      notifyError("Network error or no response from server");
    }

    throw error;
  }
};

export const fetchCustomerResource = (resource, customerId) => {
  return handleRequest("get", `/${resource}/customer/${customerId}`);
};

export const postDashboardData = (endpoint, data) => {
  return handleRequest("post", `/dashboard/${endpoint}`, data, null);  // No success message
};
