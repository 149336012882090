import React from "react";
import { Col, Tab, Tabs } from "react-bootstrap";
import UsersTab from "../Components/TabContent/UsersTab";
import AgenciesTab from "../Components/TabContent/AgenciesTab";
import ClientsTab from "../Components/TabContent/ClientsTab";
import { useAuth } from "../Auth/AuthContext";

function AdminCentrePage() {
  const { authState } = useAuth();
  const { role } = authState;

  return (
    <Col className="flex-grow-1 overflow-y-scroll bg-white my-4">
      <div className="px-3">
        <Tabs defaultActiveKey={`${role === "ROLE_BROADCASTER" || role === "ROLE_CUSTOMER" ? "agencies" : role === "ROLE_AGENCY" ? "clients" : "users"}`} id="uncontrolled-tab" className="my-3">
          {role !== "ROLE_AGENCY" && role !== "ROLE_CUSTOMER" && role !== "ROLE_BROADCASTER" && (
            <Tab eventKey="users" title="Users">
              <UsersTab />
            </Tab>
          )}
          {role !== "ROLE_AGENCY" && role !== "ROLE_AGENCY_ADMIN" && (
            <Tab eventKey="agencies" title="Agencies">
              <AgenciesTab />
            </Tab>
          )}
          {role !== "ROLE_CUSTOMER" && role !== "ROLE_CUSTOMER_ADMIN" && (
            <Tab eventKey="clients" title="Clients">
              <ClientsTab />
            </Tab>
          )}
        </Tabs>
      </div>
    </Col>
  );
}

export default AdminCentrePage;
