// API/dashboard.js
import { handleRequest, postDashboardData } from "./utils";

export const getCustomerList = () => {
  return handleRequest("get", "/dashboard/customers");
};

export const getFiltersForCustomer = (customerId) => {
  return handleRequest("get", `/dashboard/filters/${customerId}`);
};

export const getChannels = () => {
  return handleRequest("get", "/channels/");
};

export const getImpressionSummary = (data) => {
  return handleRequest("post", "/dashboard/impression_summary", data);
};

export const getImpressionsBeforeVisit = (data) => {
  return postDashboardData("impressions_before_visit", data);  // No success message
};

export const getResponsesByRegion = (data) => {
  return postDashboardData("responses_by_region", data);  // No success message
};

export const getResponsesBySite = (data) => {
  return postDashboardData("responses_by_site", data);  // No success message
};

export const getResponsesOverTime = (data) => {
  return postDashboardData("responses_over_time", data);  // No success message
};

export const getVisitResponseTiming = (data) => {
  return postDashboardData("visit_response_timing", data);  // No success message
};

// Graph Data: Creative Performance
export const getPlatformCreativePerformance = (data) => {
  return postDashboardData("platform_creative_performance", data);  // No success message
};

export const getCreativePerformanceSummary = (data) => {
  return postDashboardData("creative_performance_summary", data);  // No success message
};

export const getCreativePerformanceCost = (data) => {
  return postDashboardData("creative_performance_cost", data);  // No success message
};

// Heatmap Data
export const getImpressionHeatmap = (data) => {
  return postDashboardData("impression_heatmap", data);  // No success message
};
