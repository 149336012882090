import React from "react";
import Alert from "react-bootstrap/Alert";

const AdminNotification = ({ show, handleClose, text }) => {
  return (
    <div style={{ maxWidth: "28rem" }}>
      <Alert show={show} variant="success" dismissable>
        <div className="d-flex justify-content-between align-items-start">
          {text}
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M6 18 17.94 6M18 18 6.06 6" />
            </svg>
          </div>
        </div>
      </Alert>
    </div>
  );
};

export default AdminNotification;
