import React from "react";
import {Card, Col, ProgressBar, Row} from "react-bootstrap";
import {useData} from "../../Data/GraphContext";
import {numberWithCommas} from "../../Utilities/Utils";
import HelpTooltip from "../Shared/HelpToolTip";

const VisitResponseTiming = ({percent = false}) => {
    const {visitResponseTiming} = useData();
    const summaryData = visitResponseTiming?.summary_data || {};
    const metaData = visitResponseTiming?.meta_data || {};
    let responseTimingData = percent
        ? [
            {timing: "Within 1 hour", value: summaryData.Hour_1_Perc * 100},
            {timing: "Within 1 day", value: summaryData.Day_1_Perc * 100},
            {timing: "Within 1 week", value: summaryData.Week_1_Perc * 100}
        ]
        : [
            {timing: "Within 1 hour", value: summaryData.Hour_1},
            {timing: "Within 1 day", value: summaryData.Day_1},
            {timing: "Within 1 week", value: summaryData.Week_1}
        ];

    if (!percent) {
        // Use metaData.max_x if available, otherwise use the max value from summaryData
        const maxValue = metaData.max_x || Math.max(...responseTimingData.map((data) => data.value));
        responseTimingData = responseTimingData.map((data) => ({
            ...data,
            percentage: (data.value / maxValue) * 100
        }));
    }

    return (
      <Card className="h-100 w-100">
        <Card.Header className="bw-bg-primary text-light d-flex justify-content-between">
          <div className="fw-bold">Visit Response Timing {percent ? "%" : "#"}</div>
          <HelpTooltip helpText="Calculated as totals by percentage or number of Visits within an hour (hour 1), a day (hours 2-24), or a week (days 2-7 by 24 hour periods) of an ad impression being served."></HelpTooltip>
          <div>{/* <ExportToExcel data={responseTimingData.summary_data} fileName={percent ? "responses-over-time_percent" : "responses-over-time_count"} /> */}</div>
        </Card.Header>
        <Card.Body className="d-flex flex-column justify-content-between">
          {responseTimingData &&
            responseTimingData.map((data, index) => (
              <div className="py-1" key={index}>
                <div className="pb-1">{data.timing}</div>
                <Row>
                  <Col className="col-10 d-flex justify-content-start align-items-center ">
                    <ProgressBar now={percent ? data.value.toFixed(1) : data.percentage} className="flex-grow-1 bw-bg-secondary bw-text-primary" />
                  </Col>
                  <Col className="col-2 d-flex justify-content-end align-items-center ">
                    <div className="ms-2 fw-bold">
                      {percent ? numberWithCommas(data.value, 1) : numberWithCommas(data.value)}
                      {percent ? "%" : ""}
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </Card.Body>
      </Card>
    );
};

export default VisitResponseTiming;
