// API/admin.js
import { handleRequest } from "./utils";

export const getAssociatedCustomers = () => {
  return handleRequest("get", "/customers/?associated=true");
};

export const getUnassociatedCustomers = () => {
  return handleRequest("get", "/customers/?associated=false");
};

export const createCustomer = (customerData) => {
  return handleRequest("post", "/customers/", customerData, "Customer created successfully!");
};

export const updateCustomerName = (payload) => {
  return handleRequest("put", `/customers/${payload.id}`, payload, "Business name updated successfully");
};

export const associateCustomer = (selectedCustomer) => {
  return handleRequest("post", `/customers/${selectedCustomer.id}/associate`, {}, "Customer associated successfully!");
};


// Admin section
// Users
export const getUsers = () => {
  return handleRequest("get", `/users/`, {}, null);  // No success message
};

export const getUser = (userId) => {
  return handleRequest("get", `/users/${userId}`, {}, null);  // No success message
};

export const saveNewUser = (payload) => {
  return handleRequest("post", `/users/`, payload, "User saved successfully!", false, false);
};

export const updateUser = (payload) => {
  return handleRequest("put", `/users/${payload.id}`, payload, "User updated successfully", false, false);
};

export const deleteUser = (userId) => {
  return handleRequest("delete", `/users/${userId}`, {}, "User deleted successfully!", false, false);
};

export const deleteCustomer = (customerId) => {
  return handleRequest("delete", `/customers/${customerId}`, {}, "Customer deleted successfully!", false, false);
};

export const createAgency = (payload) => {
  return handleRequest("post", `/agencies/`, payload, "Agency saved successfully!", false, false);
};

export const updateAgency = (payload) => {
  return handleRequest("put", `/agencies/${payload.id}`, payload, "Agency updated successfully", false, false);
};

export const deleteAgency = (agencyId) => {
  return handleRequest("delete", `/agencies/${agencyId}`, {}, "Agency deleted successfully!", false, false);
};

export const associateAgency = (selectedAgency) => {
  return handleRequest("post", `/agencies/${selectedAgency.id}/associate`, {}, "Agency associated successfully!", false, false);
};

export const getAssociatedAgencies = () => {
  return handleRequest("get", `/agencies/?associated=true`, {}, null);  // No success message
};

export const getUnassociatedAgencies = () => {
  return handleRequest("get", `/agencies/?associated=false`, {}, null);  // No success message
};
