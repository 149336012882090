import React from "react";
import Modal from "react-bootstrap/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";

const NotificationModal = ({ show, handleClose, headingText, itemText, bodyText }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title className="bw-text-primary fw-bold">{headingText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3 mb-5">
          <p>
            <span className="d-inline-block me-1 fw-semibold bw-text-primary">{itemText}</span>
            {bodyText}
          </p>
        </div>
        <div className="pt-3 d-flex justify-content-end">
          <PrimaryButton text="Close" onClick={handleClose} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationModal;
