// Components/TabContent/SiteTagsTab
import React from "react";
import FilteredTable from "../Shared/FilteredTable"; // Import the reusable FilteredTable component
import TableOptions from "../TableOptions";

function SiteTagsTab({ data, showEditSiteTag }) {
  // Define the columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "tag_description",
        width: "50%",
        // Enable sorting for Name
      },
      {
        Header: "Tag",
        accessor: "tag", // Corresponds to tag.tag
        width: "40%",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => <span className="pointer">{value && value.toLowerCase()}</span>,
      },
      {
        Header: "Options",
        accessor: "options",
        width: "10%",
        disableSortBy: true,
        disableFilters: true,
        // Cell: ({ row }) => <input type="checkbox" />,
        Cell: ({ row }) => (
          <TableOptions
            // copy
            edit
            onEdit={() => showEditSiteTag(row.original)} // Pass sitetag data to the edit handler
          />
        ),
      },
      {
        Header: "",
        accessor: "edit",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => <input type="checkbox" />,
      },
    ],
    [showEditSiteTag]
  );

  return (
    <div>
      <FilteredTable columns={columns} data={data} isCopyTag /> {/* Pass data and columns */}
    </div>
  );
}

export default SiteTagsTab;
