// API/managetags.js
import { handleRequest, fetchCustomerResource } from "./utils";


export const getCampaignsForCustomer = (customer) => {
  return fetchCustomerResource("campaigns", customer.id);
};


export const saveNewCampaign = (payload) => {
  return handleRequest("post", `/campaigns/`, payload, "Campaign saved successfully!");
};

export const updateCampaign = (payload) => {
  return handleRequest("put", `/campaigns/${payload.id}`, payload, "Campaign updated.");
};

export const getBookingsForCustomer = (customer) => {
  console.log("Getting bookings for customer with id", customer.id);
  return fetchCustomerResource("bookings", customer.id);
};


export const saveNewBooking = (payload) => {
  return handleRequest("post", `/bookings/`, payload, "Booking saved successfully!");
};

export const updateBooking = (payload) => {
  return handleRequest("put", `/bookings/${payload.id}`, payload, "Booking updated.");
};


export const saveBookingCreatives = (bookingId, combinations) => {
  return handleRequest("post", `/impressiontags/save_combinations/${bookingId}`, combinations, "Booking and creative combinations saved successfully!");
};


// Creatives
export const getCreativesForCustomer = (customer) => {
  return fetchCustomerResource("creatives", customer.id);
};

export const saveNewCreative = (creative) => {
  return handleRequest("post", `/creatives/`, creative, "Creative saved successfully!");
};

export const updateCreative = (payload) => {
  return handleRequest("put", `/creatives/${payload.id}`, payload, "Creative updated.");
};

// Site Tags
export const getSiteTagsForCustomer = (customer) => {
  return fetchCustomerResource("sitetags", customer.id);
};

export const saveNewSiteTag = (payload) => {
  return handleRequest("post", `/sitetags/`, payload, "Site tag saved successfully!");
};

export const updateSiteTag = (payload) => {
  return handleRequest("put", `/sitetags/${payload.id}`, payload, "Site tag updated successfully!");
};

// Impression Tags
export const getImpressionTagsForCustomer = (customer) => {
  return fetchCustomerResource("impressiontags", customer.id);
};

export const saveImpressionsTag = (payload) => {
  return handleRequest("post", `/impressiontags/`, payload, "Impression tag saved successfully!");
};

export const getChannels = () => {
  return handleRequest("get", "/channels/");
};

export const updateImpressionTag = (payload) => {
  return handleRequest("put", `/impressiontags/${payload.id}`, payload, "Impression tag updated successfully!");
};
