// Pages/HomePage.js
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopNav from "../Components/Nav/TopNav";
import DashboardPage from "./DashboardPage";
import AdminCentrePage from "./AdminCentrePage";
import ManageTags from "./ManageTags";

//Notifications
import "react-toastify/dist/ReactToastify.css";
import { GraphProvider } from "../Data/GraphContext";

function HomePage() {
  const [view, setView] = useState("dashboard");
  const [currentCustomerId, setCurrentCustomerId] = useState(null);

  const updateCurrentCustomer = (newCustomer) => {
    setCurrentCustomerId(newCustomer);
  };

  return (
    <GraphProvider>
      <Container fluid className="g-0 d-flex flex-column vh-100 overflow-x-hidden">
        <Row className="d-flex flex-column flex-grow-1 h-100 m-0 no-padding-row">
          <Col className="flex-grow-0 p-0">
            <TopNav setView={setView} />
          </Col>

          {view &&
            (view === "manage" ? (
              <ManageTags currentCustomerId={currentCustomerId} setCurrentCustomerId={updateCurrentCustomer} />
            ) : view === "admin" ? (
              <AdminCentrePage />
            ) : (
              <Col className="flex-grow-1 overflow-y-scroll">
                <DashboardPage currentCustomerId={currentCustomerId} setCurrentCustomerId={updateCurrentCustomer} />
              </Col>
            ))}
        </Row>
      </Container>
    </GraphProvider>
  );
}

export default HomePage;
