// Components/Button/BookingTypeButtonGroup.js
import React, { useEffect, useState } from "react";

function BookingTypeButtonGroup({ onTypeChange, selectedType }) {
  const [selectedBookingType, setSelectedBookingType] = useState(selectedType === 2 ? "image" : "video");

  const handleBookingTypeSelection = (type_int, e) => {
    e.preventDefault(); // Prevent the form from submitting
    if (type_int === 1) {
      setSelectedBookingType("video");
      onTypeChange(1);
      return;
    }

    if (type_int === 2) {
      setSelectedBookingType("image");
      onTypeChange(2);
    }
  };

  useEffect(() => {
    if (selectedType === 1) {
      handleBookingTypeSelection(1, {
        preventDefault: () => {},
      });
    } else if (selectedType === 2) {
      handleBookingTypeSelection(2, {
        preventDefault: () => {},
      });
    }
  }, [selectedType]);

  return (
    <>
      <div className="tiny-heading mb-2">Type</div>
      <div className="d-flex gap-3 align-items-center">
        <button className={`bw-button button-rounded ${selectedBookingType === "video" ? "bw-button-primary--alt" : "bw-button-secondary"}`} onClick={(e) => handleBookingTypeSelection(1, e)}>
          Video
        </button>

        <button className={`bw-button button-rounded ${selectedBookingType === "image" ? "bw-button-primary--alt" : "bw-button-secondary"}`} onClick={(e) => handleBookingTypeSelection(2, e)}>
          Image
        </button>
      </div>
    </>
  );
}

export default BookingTypeButtonGroup;
